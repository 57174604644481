import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injectable, LOCALE_ID,
  NgModule
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from '@shared/login/login.component';
import { NotFoundComponent } from '@shared/not-found/not-found.component';
import { MainLayoutComponent } from '@shared/main-layout/main-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { MaterialModule } from '@core/modules/material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { HttpTokenInterceptor } from '@core/interceptor/http.interceptor';
import { CountProfitComponent } from '@crm/analytics/count-profit/count-profit.component';
import { PrimeModule } from '@core/modules/prime-module';
import { ReturnPageComponent } from '@crm/analytics/return-page/return-page.component';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { DialogMoreInfoComponent } from '@crm/analytics/return-page/dialog-more-info/dialog-more-info.component';
import { GrossPageComponent } from '@crm/analytics/gross-page/gross-page.component';
import { SalaryPageComponent } from '@crm/analytics/salary-page/salary-page.component';
import { DialogInfoComponent } from '@crm/analytics/salary-page/dialog-info/dialog-info.component';
import { DialogGrossInfoComponent } from '@crm/analytics/gross-page/dialog-gross-info/dialog-gross-info.component';
import { DialogCountOrderComponent } from '@crm/analytics/count-profit/dialog-count-order/dialog-count-order.component';
import { DialogCountTopComponent } from '@crm/analytics/count-profit/dialog-count-top/dialog-count-top.component';
import { DialogSummaryComponent } from '@crm/analytics/count-profit/dialog-summary/dialog-summary.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DialogGrossTopComponent } from '@crm/analytics/gross-page/dialog-gross-top/dialog-gross-top.component';
import { DialogGrossSummaryComponent } from '@crm/analytics/gross-page/dialog-summary/dialog-gross-summary.component';
import { DialogMoreTopComponent } from '@crm/analytics/return-page/dialog-more-top/dialog-more-top.component';
import { ClientProfitComponent } from '@crm/analytics/client-profit/client-profit.component';
import { DialogProductOnStockComponent } from '@crm/analytics/return-page/dialog-product-on-stock/dialog-product-on-stock.component';
import { CallListComponent } from '@crm/sale/call-list/call-list.component';
import { ProdProfitComponent } from '@crm/analytics/prod-profit/prod-profit.component';
import { DialogMoreCallOrderComponent } from '@crm/analytics/count-profit/dialog-more-call-order/dialog-more-call-order.component';
import { StructUnitDicComponent } from '@crm/settings/struct-unit-dic/struct-unit-dic.component';
import { DialogAddPaymentComponent } from '@crm/settings/struct-unit-dic/dialog-add-payment/dialog-add-payment.component';
import { DialogAddNewDeliveryApiComponent } from '@crm/settings/struct-unit-dic/dialog-add-new-delivery-api/dialog-add-new-delivery-api.component';
import { DialogLoadMoreOrderInfoComponent } from '@crm/settings/struct-unit-dic/dialog-load-more-order-info/dialog-load-more-order-info.component';
import { BtnInfoComponent } from '@shared/btn-info/btn-info.component';
import { ProviderCountTableComponent } from '@shared/provider-count-table/provider-count-table.component';
import { PackingComponent } from '@crm/sale/delivery/packing/packing.component';
import { PackingDialogCreateTtnComponent } from '@crm/sale/delivery/packing/packing-dialog-create-ttn/packing-dialog-create-ttn.component';
import { PackingDialogOrderComponent } from '@crm/sale/delivery/packing/packing-dialog-order/packing-dialog-order.component';
import { PackingDialogSaveSelfTtnComponent } from '@crm/sale/delivery/packing/packing-dialog-save-self-ttn/packing-dialog-save-self-ttn.component';
import { UserCardLibraryComponent } from '@crm/settings/user-card-library/user-card-library.component';
import { UserCardComponent } from '@crm/settings/user-card-library/user-card/user-card.component';
import { DialogYesNoComponent } from '@shared/dialog-yes-no/dialog-yes-no.component';
import { UcDialogAddNewPaymentComponent } from '@crm/settings/user-card-library/user-card/uc-dialog-add-new-payment/uc-dialog-add-new-payment.component';
import { UcDialogEditPaymentComponent } from '@crm/settings/user-card-library/user-card/uc-dialog-edit-payment/uc-dialog-edit-payment.component';
import { UcDialogAddNewRecipientComponent } from '@crm/settings/user-card-library/user-card/uc-dialog-add-new-recipient/uc-dialog-add-new-recipient.component';
import { UcDialogEditRecipientComponent } from '@crm/settings/user-card-library/user-card/uc-dialog-edit-recipient/uc-dialog-edit-recipient.component';
// tslint:disable-next-line:max-line-length
import { UcDialogAddNewCarComponent } from '@crm/settings/user-card-library/user-card/uc-dialog-add-new-car/uc-dialog-add-new-car.component';
import { UcDialogEditCarComponent } from '@crm/settings/user-card-library/user-card/uc-dialog-edit-car/uc-dialog-edit-car.component';
import { UcDialogEditUserInfoComponent } from '@crm/settings/user-card-library/user-card/uc-dialog-edit-user-info/uc-dialog-edit-user-info.component';
import { UserCardPhoneComponent } from '@crm/settings/user-card-library/user-card-phone/user-card-phone.component';
import { UcpDialogChangeCarComponent } from '@crm/settings/user-card-library/user-card-phone/ucp-dialog-change-car/ucp-dialog-change-car.component';
import { UcpDialogOrderListComponent } from '@crm/settings/user-card-library/user-card-phone/ucp-dialog-order-list/ucp-dialog-order-list.component';

import { RomiComponent } from '@crm/analytics/romi/romi.component';
import { DialogMarkComponent } from '@crm/analytics/romi/dialog-mark/dialog-mark.component';
import { RomiDialogModelComponent } from '@crm/analytics/romi/romi-dialog-model/romi-dialog-model.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TaskDialogCreateNewTaskComponent } from '@crm/sale/task/task-dialog-create-new-task/task-dialog-create-new-task.component';
import { TaskDialogCreatePretensionComponent } from '@crm/sale/task/task-dialog-create-pretension/task-dialog-create-pretension.component';

import { PretensionComponent } from '@crm/sale/task/pretension/pretension.component';
import { TaskComponent } from '@crm/sale/task/task/task.component';
import { TaskDialogConfirmTaskComponent } from '@crm/sale/task/task-dialog-confirm-task/task-dialog-confirm-task.component';
// tslint:disable-next-line:max-line-length
import { TaskDialogChangeTaskComponent } from '@crm/sale/task/task-dialog-change-task/task-dialog-change-task.component';
import { DisableBlockComponent } from '@shared/disable-block/disable-block.component';
import { OrderChatComponent } from '@shared/chat/order-chat/order-chat.component';
import { WarehouseChatComponent } from '@shared/chat/warehouse-chat/warehouse-chat.component';
import { OrderChatListComponent } from '@shared/chat/order-chat-list/order-chat-list.component';
import { OrderResponseComponent } from '@shared/chat/order-response/order-response.component';
import { WarehouseChatListComponent } from '@shared/chat/warehouse-chat-list/warehouse-chat-list.component';
import { WarehouseChatResponseComponent } from '@shared/chat/warehouse-chat-response/warehouse-chat-response.component';
import { SmallSearchComponent } from '@crm/search/small-search/small-search.component';
import { GlobalSearchComponent } from '@crm/search/global-search/global-search.component';
import { SDialogShowStockInfoComponent } from '@crm/search/s-dialog-show-stock-info/s-dialog-show-stock-info.component';
import { SDialogShowSettingsInfoComponent } from '@crm/search/s-dialog-show-settings-info/s-dialog-show-settings-info.component';
import { FormAddToOrderComponent } from '@crm/search/form-add-to-order/form-add-to-order.component';
import { ProductCardComponent } from '@crm/search/product-card/product-card.component';
import { ImgGalleryComponent } from '@crm/search/img-gallery/img-gallery.component';

import { TaskDialogChangeManagerComponent } from '@crm/sale/task/task-dialog-change-manager/task-dialog-change-manager.component';
import { CallListCreateOrderComponent } from '@crm/sale/call-list/call-list-create-order/call-list-create-order.component';
import { DialogApplicabilityCarInfoComponent } from '@crm/search/product-card/dialog-applicability-carInfo/dialog-applicability-car-info.component';
import { OrderChatOrderListComponent } from '@shared/chat/order-chat-order-list/order-chat-order-list.component';
import { WarehouseChatOrderListComponent } from '@shared/chat/warehouse-chat-order-list/warehouse-chat-order-list.component';
import {
  SelectionCatalogComponent,
} from '@crm/sale/selection-catalog/selection-catalog.component';
import { RedirectCatalogComponent } from '@shared/redirect-catalog/redirect-catalog.component';
import { LogProductComponent } from '@crm/warehouse/log-product/log-product.component';
import { ScrollTopComponent } from '@shared/scroll-top/scroll-top.component';
import { RandomProductModule } from '@crm/sale/random-product/add-product/random-product.module';
import { RandomRequestListModule } from '@crm/sale/random-product/random-request-list/random-request-list.module';
import { RandomRequestPaymentListModule } from '@crm/sale/random-product/random-request-payment-list/random-request-payment-list.module';
import { RandomRequestApproveModule } from '@crm/sale/random-product/random-request-approve/random-request-approve.module';
import { SpecInfoDialogModule } from '@crm/search/dialog/spec-info-dialog/spec-info-dialog.module';
import { AddRecommendationOnNameModule } from '@crm/search/product-card/dialog/add-recomendation-on-name/add-recommendation-on-name.module';
import { AddRecommendationOnCriModule } from '@crm/search/product-card/dialog/add-recommendation-on-cri/add-recommendation-on-cri.module';
import { AddRecommendationOnCrossModule } from '@crm/search/product-card/dialog/add-recommendation-on-cross/add-recommendation-on-cross.module';
import { AddRecommendationBrandModule } from '@crm/search/product-card/dialog/add-recommendation-brand/add-recommendation-brand.module';
import { WaitWindowLoadingModule } from '@shared/wait-window-loading/wait-window-loading.module';
import { DialogWarehouseInfoComponent } from '@crm/search/product-card/dialog/dialog-warehouse-info.component/dialog-warehouse-info.component';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { HeaderModule } from 'header';
import { NotificationModule } from 'notification';
import { FORMATS_DATE_PICKER, SafePipeModule } from 'repositories';
import { AlertPackingDialogComponent } from '@crm/sale/delivery/packing/alert-packing-dialog/alert-packing-dialog.component';
import { BarcodeScannerModule } from 'barcode-scanner';

/*Sentry.init({
  dsn: environment.sentryDNS,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});*/

/*export class SentryErrorHandler implements ErrorHandler {
  // tslint:disable-next-line:typedef
   handleError(error: any) {
    if (environment.production)
      Sentry.captureException(error.originalError || error);
  }
}*/
// @ts-ignore
@Injectable()
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    MainLayoutComponent,
    CountProfitComponent,
    ReturnPageComponent,
    DialogMoreInfoComponent,
    GrossPageComponent,
    SalaryPageComponent,
    DialogInfoComponent,
    DialogGrossInfoComponent,
    DialogCountOrderComponent,
    DialogCountTopComponent,
    DialogSummaryComponent,
    DialogGrossTopComponent,
    DialogGrossSummaryComponent,
    DialogMoreTopComponent,
    ClientProfitComponent,
    DialogProductOnStockComponent,
    CallListComponent,
    ProdProfitComponent,
    DialogMoreCallOrderComponent,
    StructUnitDicComponent,
    DialogAddPaymentComponent,
    DialogAddNewDeliveryApiComponent,
    DialogLoadMoreOrderInfoComponent,
    BtnInfoComponent,
    ProviderCountTableComponent,
    PackingComponent,
    PackingDialogCreateTtnComponent,
    PackingDialogOrderComponent,
    PackingDialogSaveSelfTtnComponent,
    UserCardLibraryComponent,
    UserCardComponent,
    DialogYesNoComponent,
    UcDialogAddNewPaymentComponent,
    UcDialogEditPaymentComponent,
    UcDialogAddNewRecipientComponent,
    UcDialogEditRecipientComponent,
    UcDialogAddNewCarComponent,
    UcDialogEditCarComponent,
    UcDialogEditUserInfoComponent,
    UserCardPhoneComponent,
    UcpDialogChangeCarComponent,
    UcpDialogOrderListComponent,
    RomiComponent,
    DialogMarkComponent,
    RomiDialogModelComponent,
    TaskComponent,
    TaskDialogCreateNewTaskComponent,
    TaskDialogCreatePretensionComponent,
    TaskDialogCreatePretensionComponent,
    PretensionComponent,
    TaskDialogConfirmTaskComponent,
    TaskDialogChangeTaskComponent,
    DisableBlockComponent,
    OrderChatComponent,
    WarehouseChatComponent,
    OrderChatListComponent,
    OrderResponseComponent,
    WarehouseChatListComponent,
    WarehouseChatResponseComponent,
    SmallSearchComponent,
    GlobalSearchComponent,
    SDialogShowStockInfoComponent,
    SDialogShowSettingsInfoComponent,
    FormAddToOrderComponent,
    ProductCardComponent,
    ImgGalleryComponent,
    TaskDialogChangeManagerComponent,
    CallListCreateOrderComponent,
    DialogApplicabilityCarInfoComponent,
    OrderChatOrderListComponent,
    WarehouseChatOrderListComponent,
    SelectionCatalogComponent,
    SelectionCatalogComponent,
    RedirectCatalogComponent,
    LogProductComponent,
    ScrollTopComponent,
    DialogWarehouseInfoComponent,
    AlertPackingDialogComponent,
  ],
  imports: [
    SafePipeModule,
    HeaderModule,
    AddRecommendationOnNameModule,
    AddRecommendationOnCriModule,
    AddRecommendationOnCrossModule,
    AddRecommendationBrandModule,
    RandomProductModule,
    RandomRequestListModule,
    RandomRequestPaymentListModule,
    RandomRequestApproveModule,
    BrowserModule,
    AppRoutingModule,
    SpecInfoDialogModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    // NgxCaptchaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'uk'
    }),
    NgxMaskModule.forRoot(),
    PrimeModule,
    NotificationModule,
    WaitWindowLoadingModule,
    BarcodeScannerModule
  ],
  exports: [
    TranslateModule,
    TaskComponent,
    ProviderCountTableComponent,
    WarehouseChatOrderListComponent,
    OrderChatOrderListComponent,
    BtnInfoComponent
  ],
  providers: [
    // { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    DatePipe,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'ru-Ru' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: FORMATS_DATE_PICKER },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
