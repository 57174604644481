import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PsService } from '../core/ps.service';
import { Observable, of, Subject } from 'rxjs';
import { PackingOrderLowInfo } from '../core/pi';
import { FormControl, Validators } from '@angular/forms';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-packing-dialog-order',
  templateUrl: './packing-dialog-order.component.html',
  styleUrls: ['./packing-dialog-order.component.scss'],
  providers: [PsService],
})
export class PackingDialogOrderComponent implements OnInit, OnDestroy {
  info: boolean;
  packingOrderLowInfoList$: Observable<PackingOrderLowInfo> = this.ps
    .getOrderInfoToLowPack(this.dialogData.orderList)
    .pipe(
      tap((val) => {
        if (+val.managerList[0].delivery === 2) {
          this.sendSms.setValue(true);
          this.info = true;
        } else {
          this.sendSms.setValue(false);
          this.info = false;
        }
      })
    );
  arrBox = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  countBox: FormControl;
  createTask: FormControl;
  sendSms: FormControl;
  private destroy = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private ps: PsService,
    private srv: SrvService,
    private dialogRef: MatDialogRef<PackingDialogOrderComponent>
  ) {
    this.countBox = new FormControl(1, [Validators.required]);
    this.createTask = new FormControl(true, [Validators.required]);
    this.sendSms = new FormControl(true, [Validators.required]);
  }

  ngOnInit(): void {}

  setPackOrder(): void {
    this.ps
      .setPackOrder({
        orderNumber: this.dialogData.orderList,
        boxCount: this.countBox.value,
        createTask: this.createTask.value,
        sendSms: this.sendSms.value,
      })
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при упаковке заказа. Обратитесь в техподдержку'
          );
          return of('Error in method onInit addNewPayment ' + err);
        }),
        takeUntil(this.destroy)
      )
      .subscribe((val) => {
        this.dialogRef.close(val);
        this.srv.successMessage('Заказ отгружен');
      });
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }
}
