import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationListComponent } from './navigation-list.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [NavigationListComponent],
  imports: [CommonModule, RouterModule, MatButtonModule],
  exports: [NavigationListComponent],
})
export class NavigationListModule {}
