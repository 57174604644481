import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-redirect-catalog',
  templateUrl: './redirect-catalog.component.html',
  styleUrls: ['./redirect-catalog.component.scss'],
})
export class RedirectCatalogComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private srv: SrvService
  ) {}

  ngOnInit(): void {
    this.setRedirect();
  }

  setRedirect(): void {
    this.srv.userPerm.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      setTimeout(() => {
        if (value.workmanId) {
          this.router.navigate([
            '/crm',
            'search-global',
            this.activeRouter.snapshot.params.katal,
            this.activeRouter.snapshot.params.brand,
          ]);
        } else {
          window.location.href =
            'https://avto100.in.ua/search/' +
            this.activeRouter.snapshot.params.katal +
            '/' +
            this.activeRouter.snapshot.params.brand;
        }
      }, 300);
    });
  }
  //
  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
