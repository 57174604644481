import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { SearchService } from '@crm/search/core/search.service';
import { DestroyService } from '@core/services/destroy.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-add-recommendation-brand',
  templateUrl: './add-recommendation-brand.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AddRecommendationBrandComponent implements OnInit {
  formNomen: FormGroup = new FormGroup({
    commentRec: new FormControl('', [Validators.required]),
  });

  constructor(
    private ss: SearchService,
    private srv: SrvService,
    private des$: DestroyService,
    private dialogRef: MatDialogRef<AddRecommendationBrandComponent>,
    @Inject(MAT_DIALOG_DATA) public dataObj: any // Инжектирование Переменной для приема данных из вне, Объект
  ) {}

  ngOnInit(): void {}

  saveBrandInfo(): boolean {
    if (this.formNomen.invalid) {
      this.srv.errorMessage('Неверно заполненная форма номенклатуры');

      return false;
    }

    this.ss
      .saveBrandRecommendation(
        this.formNomen.value,
        this.dataObj.katalNumber,
        this.dataObj.brand
      )
      .pipe(takeUntil(this.des$))
      .subscribe((value) => {
        if (value && +value.code === 200) {
          this.dialogRef.close();
          this.srv.successMessage('Рекомендация сохранена');
        }
      });
  }
}
