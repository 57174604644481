import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { SearchService } from '../core/search.service';
import { ProductCard } from '../core/search';
import { DialogApplicabilityCarInfoComponent } from './dialog-applicability-carInfo/dialog-applicability-car-info.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { AddRecommendationOnNameComponent } from '@crm/search/product-card/dialog/add-recomendation-on-name/add-recommendation-on-name.component';
import { AddRecommendationOnCriComponent } from '@crm/search/product-card/dialog/add-recommendation-on-cri/add-recommendation-on-cri.component';
import { DialogYesNoComponent } from '@shared/dialog-yes-no/dialog-yes-no.component';
import { AddRecommendationOnCrossComponent } from '@crm/search/product-card/dialog/add-recommendation-on-cross/add-recommendation-on-cross.component';
import { Title } from '@angular/platform-browser';
import { AddRecommendationBrandComponent } from '@crm/search/product-card/dialog/add-recommendation-brand/add-recommendation-brand.component';
import { DialogWarehouseInfoComponent } from '@crm/search/product-card/dialog/dialog-warehouse-info.component/dialog-warehouse-info.component';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  providers: [SearchService],
})
export class ProductCardComponent implements OnInit, OnDestroy {
  destroy = new Subject();
  productInfo$: Observable<ProductCard>;
  prodInfo: ProductCard;
  oeNum;
  specInfo: FormControl = new FormControl('', Validators.required);
  prodCard: number[];

  constructor(
    private route: ActivatedRoute,
    private srv: SrvService,
    private search: SearchService,
    private title: Title,
    private dialog: MatDialog
  ) {
    this.srv.userPerm
      .pipe(filter((val) => val != null))
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        this.prodCard = value.permission.filter((val) => +val === 81);
      });
  }

  ngOnInit(): void {
    this.loadProdInfo(this.route.snapshot.params.code);
    this.oeNum = this.route.snapshot.queryParams.oe;
  }

  loadProdInfo(code): void {
    this.productInfo$ = this.search.loadProdInfo(code).pipe(
      tap((val) => {
        this.prodInfo = val;
        this.title.setTitle(
          this.prodInfo.katalNumber + ' ' + this.prodInfo.brand
        );
      })
    );
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }

  onOpenApplicabilityCar(mark, model): void {
    const dialogRef = this.dialog.open(DialogApplicabilityCarInfoComponent, {
      height: '80%',
      width: '100%',

      data: {
        mark,
        model,
        list: this.prodInfo.listUsed,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      switch (result.code) {
        case 200:
          this.ngOnInit();
          break;
      }
    });
  }

  trueCopy(): void {
    this.srv.successMessage('Скопировано');
  }

  // сохранить спец информацию о товаре
  onSaveSpecInfo(): void {
    this.search
      .onSaveSpecInfo(this.route.snapshot.params.code, this.specInfo.value)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.specInfo.setValue('');
        this.loadProdInfo(this.route.snapshot.params.code);
      });
  }

  setRecNomen(): void {
    this.dialog.open(AddRecommendationOnNameComponent, {
      maxHeight: '80vh',
      maxWidth: '80vh',
      minWidth: '50vh',
      data: {
        katalNumber: this.prodInfo.katalNumber,
        brand: this.prodInfo.brand,
      },
    });
  }

  setRecCri(): void {
    this.dialog.open(AddRecommendationOnCriComponent, {
      maxHeight: '80vh',
      maxWidth: '80vh',
      minWidth: '50vh',
      data: {
        katalNumber: this.prodInfo.katalNumber,
        brand: this.prodInfo.brand,
      },
    });
  }

  deleteCri(idCri: any, nameCi): void {
    const dialog = this.dialog.open(DialogYesNoComponent, {
      minWidth: '50vw',
      maxWidth: '50vw',
      maxHeight: '50vh',
      data: {
        title: 'Удаление критерий',
        text:
          'Вы собираетесь удалить критерий. Изменения вступят в силу после обработки модератором<br> <h3>Вы уверены?</h3>',
        dismiss: 'Отмена',
        edit: 'Удалить и изменить',
        success: 'Удалить',
      },
    });

    dialog
      .afterClosed()
      .pipe(
        takeUntil(this.destroy),
        filter((value) => !!value.result)
      )
      .subscribe((val) => {
        if (val.result === 'edit') {
          this.search
            .deleteRecCri({
              katalNumber: this.prodInfo.katalNumber,
              brand: this.prodInfo.brand,
              idCri,
            })
            .pipe(takeUntil(this.destroy))
            .subscribe((value) => {
              if (value && +value.code === 200) {
                this.dialog.open(AddRecommendationOnCriComponent, {
                  maxHeight: '80vh',
                  maxWidth: '80vh',
                  minWidth: '50vh',
                  data: {
                    katalNumber: this.prodInfo.katalNumber,
                    brand: this.prodInfo.brand,
                    nameCi,
                  },
                });
              }
            });
        } else if (val.result === true) {
          this.search
            .deleteRecCri({
              katalNumber: this.prodInfo.katalNumber,
              brand: this.prodInfo.brand,
              idCri,
            })
            .pipe(takeUntil(this.destroy))
            .subscribe((value) => {
              if (value && +value.code === 200) {
                this.srv.successMessage('Заявка на удаление создана');
              }
            });
        }
      });
  }

  setRecCross(): void {
    this.dialog.open(AddRecommendationOnCrossComponent, {
      maxHeight: '80vh',
      maxWidth: '80vh',
      minWidth: '50vh',
      data: {
        katalNumber: this.prodInfo.katalNumber,
        brand: this.prodInfo.brand,
      },
    });
  }

  setBrandRec(): void {
    this.dialog.open(AddRecommendationBrandComponent, {
      maxHeight: '80vh',
      maxWidth: '80vh',
      minWidth: '50vh',
      data: {
        katalNumber: this.prodInfo.katalNumber,
        brand: this.prodInfo.brand,
      },
    });
  }

  showWarehouseInfo(text): void {
    this.dialog.open(DialogWarehouseInfoComponent, {
      maxHeight: '80vh',
      maxWidth: '80vh',
      minWidth: '50vh',
      data: {
        text,
      },
    });
  }
}
