import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductCard, ProductItem } from './search';
import { RequestBody } from '../../../core/interfaces/interfaces';
import { ApiService } from '../../../core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private api: ApiService) {}

  loadSearchProduct(katalNumber, brand): Observable<ProductItem> {
    const q: RequestBody = {
      modelName: 'SearchNew',
      calledMethod: 'loadSearchProduct',
      methodProperties: {
        type: 1,
        katalNumber,
        brand,
      },
    };
    return this.api.post_jwt(q);
  }

  onAddProduct(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Search',
      calledMethod: 'onAddProductToOrder',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  loadProdInfo(code): Observable<ProductCard> {
    const q: RequestBody = {
      modelName: 'Product',
      calledMethod: 'loadProdInfo',
      methodProperties: {
        type: 1,
        code,
      },
    };
    return this.api.post_jwt(q);
  }

  searchOENum(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'searchOENum',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  onSaveSpecInfo(code, comment): Observable<any> {
    const q: RequestBody = {
      modelName: 'Search',
      calledMethod: 'onSaveSpecInfo',
      methodProperties: {
        type: 1,
        code,
        comment,
      },
    };
    return this.api.post_jwt(q);
  }

  loadNomenProdInfo(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductNew',
      calledMethod: 'loadNomenInfo',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  seveNomenRecomendation(
    value: any,
    katalNumber: string,
    brand: string
  ): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductNew',
      calledMethod: 'seveNomenRecomendation',
      methodProperties: {
        type: 1,
        katalNumber,
        brand,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  saveRecProductCri(value): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductNew',
      calledMethod: 'saveRecProductCri',
      methodProperties: {
        type: 1,
        ...value,
      },
    };
    return this.api.post_jwt(q);
  }

  deleteRecCri(param: {
    idCri: any;
    brand: string;
    katalNumber: string;
  }): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductNew',
      calledMethod: 'deleteRecCri',
      methodProperties: {
        type: 1,
        ...param,
      },
    };
    return this.api.post_jwt(q);
  }

  loadCrossList(param: { brand: any; katalNumber: any }): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductNew',
      calledMethod: 'loadCrossList',
      methodProperties: {
        type: 1,
        ...param,
      },
    };
    return this.api.post_jwt(q);
  }

  deleteCross(param: {
    brand: any;
    katalNumber: any;
    crossProd: any;
  }): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductNew',
      calledMethod: 'deleteCross',
      methodProperties: {
        type: 1,
        ...param,
      },
    };
    return this.api.post_jwt(q);
  }

  loadBrandCrossList(param: { katalNumber: any }): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductNew',
      calledMethod: 'loadBrandCrossList',
      methodProperties: {
        type: 1,
        ...param,
      },
    };
    return this.api.post_jwt(q);
  }

  addNewCross(value: any, param2: any): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductNew',
      calledMethod: 'addNewCross',
      methodProperties: {
        type: 1,
        ...value,
        ...param2,
      },
    };
    return this.api.post_jwt(q);
  }

  saveBrandRecommendation(value, katalNumber, brand): Observable<any> {
    const q: RequestBody = {
      modelName: 'ProductNew',
      calledMethod: 'saveBrandRecommendation',
      methodProperties: {
        type: 1,
        ...value,
        katalNumber,
        brand,
      },
    };
    return this.api.post_jwt(q);
  }
}
