import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HeaderDopSaleListComponent } from '../header-dop-sale-list/header-dop-sale-list.component';
import { HeaderService } from '../../core/header.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ApiRequest,
  IDopSaleItem,
  IDopSaleList,
  IResponseServer,
  ISprList,
  SrvService,
} from 'repositories';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { Title } from '@angular/platform-browser';

@UntilDestroy()
@Component({
  selector: 'lib-header-dop-sale',
  templateUrl: './header-dop-sale.component.html',
})
export class HeaderDopSaleComponent implements OnInit {
  title: string;
  orderList$: Observable<any> = this.hs.loadOrderList();
  productTreeList: IDopSaleList[] = [];
  private productTreeListBase: IDopSaleList[] = [];
  trigger$ = new Subject();


  form: FormGroup;
  loadFastProduct$: Observable<IDopSaleList[]> = this.trigger$.pipe(
    startWith(1),
    switchMap((val) => this.hs.loadFastProduct(val)),
    tap((list) => {
      this.productTreeList = list;
      this.productTreeListBase = cloneDeep(list);
      this.srv.showLoadInfo$.next({ status: false });
    })
  );
  searchTree: FormControl = new FormControl(null);

  clientCriList$: Observable<ISprList[]> = this.hs
    .postRequest(
      ApiRequest.productCardLibraryClass.name,
      ApiRequest.productCardLibraryClass.method.clientCriList
    )
    .pipe(
      map((data: IResponseServer<ISprList[]>) => {
        this.srv.showLoadInfo$.next({
          status: false,
          titleSpinner: 'Ожидайте идет загрузка',
        });

        return data.result ?? [];
      })
    );

  clientCri: FormControl = new FormControl('');

  constructor(
    private srv: SrvService,
    private dialog: MatDialog,
    private hs: HeaderService,
    private titlePage: Title
  ) {
    this.title = 'Допродажи';
    this.titlePage.setTitle('Допродажи');

    this.form = new FormGroup({
      check1: new FormControl(''),
      check2: new FormControl(''),
      check3: new FormControl(''),
      delivery: new FormControl(100),
      send: new FormControl(''),
      dvs: new FormControl(''),
      orderList: new FormControl('', [Validators.required]),
    });
  }

  addProductToOrder(): any {
    if (this.form.invalid) {
      this.srv.successMessage('Заполнены не все поля');
      return false;
    }

    this.hs
      .addProductToOrder(this.form.value)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.srv.successMessage('Товар добавлен в заказ');
      });
  }

  loadDataInfoProd(tree: IDopSaleItem): void {
    this.dialog.open(HeaderDopSaleListComponent, {
      minHeight: '100vh',
      maxHeight: '100vh',
      minWidth: '100vw',
      maxWidth: '100vw',
      data: { id: tree.id, name: tree.name, clientCriId: this.clientCri.value },
    });
  }

  ngOnInit(): void {
    this.srv.showLoadInfo$.next({
      status: true,
      titleSpinner: 'Ожидайте идет загрузка',
    });
    this.subscribeToSearch();
  }

  loadDopSaleTreeList(): void {
    this.trigger$.next({ clientCri: this.clientCri.value });
  }

  private subscribeToSearch(): void {
    this.searchTree.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((search) => {
        this.productTreeList = cloneDeep(this.productTreeListBase);
        this.productTreeList.filter((record) => {
          record.tree = record.tree.filter((item) => {
            return item.name.toString().toLowerCase().indexOf(search) !== -1;
          });
          return record.tree.length;
        });
      });
  }
}
