import { Component, OnInit } from '@angular/core';
import { INotificationMessage, SrvService } from 'repositories';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'lib-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  ntfList: INotificationMessage[] = [];

  constructor(public readonly srv: SrvService) {}

  ngOnInit(): void {
    this.srv.toast$.pipe(filter((item) => !!item)).subscribe((toast) => {
      this.ntfList = [toast, ...this.ntfList];
    });
  }

  remove(index: number): void {
    this.ntfList = this.ntfList.filter((v, i) => i !== index);
  }
}
