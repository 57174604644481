import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { OtherSpr } from '@core/interfaces/interfaces';
import { PsService } from '@crm/sale/delivery/packing/core/ps.service';

@Component({
  selector: 'app-packing-dialog-save-self-ttn',
  templateUrl: './packing-dialog-save-self-ttn.component.html',
  styleUrls: ['./packing-dialog-save-self-ttn.component.scss'],
  providers: [PsService],
})
export class PackingDialogSaveSelfTtnComponent implements OnInit {
  ttn: FormControl = new FormControl('', [Validators.required]);

  unitId: FormControl = new FormControl('', [Validators.required]);
  unitList$: Observable<OtherSpr[]> = this.ps.loadUnitList();

  constructor(
    private ps: PsService,
    private dialogRef: MatDialogRef<PackingDialogSaveSelfTtnComponent>
  ) {}

  ngOnInit(): void {}

  saveTtn(): void {
    if (this.ttn.invalid || this.unitId.invalid) {
      return;
    }

    this.dialogRef.close({ ttn: this.ttn.value, unitId: this.unitId.value });
  }
}
