import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  Inject,
} from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RraService } from '../core/rra.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OtherSpr } from '@core/interfaces/interfaces';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SrvService } from 'repositories';
@UntilDestroy()
@Component({
  selector: 'app-random-request-approve',
  templateUrl: './random-request-approve.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RraService],
})
export class RandomRequestApproveComponent implements OnInit, OnDestroy {
  loadProductList;
  addAnalog: any;

  title: any;

  constructor(
    private rra: RraService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<RandomRequestApproveComponent>,
    private srv: SrvService
  ) {
    this.title = 'Добавить произвольный товар';
  }

  countList = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    40,
  ];
  timeDeliveryList = [
    { name: '0-1', value: 1 },
    { name: '1-2', value: 2 },
    { name: '2-3', value: 3 },
    { name: '3-4', value: 4 },
    { name: 'более 4', value: 5 },
  ];
  destroy$ = new ReplaySubject();

  form: FormGroup = new FormGroup({
    id: new FormControl(this.dialogData.item.id, Validators.required),
    orderNumber: new FormControl(
      this.dialogData.item.orderNumber,
      Validators.required
    ),
    nomen: new FormControl('', Validators.required),
    katalNumber: new FormControl('', Validators.required),
    katalNumberAnalog: new FormControl(''),
    countAnalog: new FormControl(''),
    brandAnalog: new FormControl(''),
    brand: new FormControl('', Validators.required),
    count: new FormControl(1, Validators.required),
    priceCost: new FormControl('', Validators.required),
    siteCost: new FormControl('', Validators.required),
    zakazNumber: new FormControl('', Validators.required),
    timeDelivery: new FormControl('', Validators.required),
    comment: new FormControl(''),
    commentPayment: new FormControl(''),
    checkAnalog: new FormControl(''),
  });
  loadProviderList$: Observable<OtherSpr[]> = this.rra.loadProviderList();

  ngOnInit(): void {
    this.form.controls.nomen.setValue(this.dialogData.item.nomen);
    this.form.controls.katalNumber.setValue(this.dialogData.item.katalNumber);
    this.form.controls.brand.setValue(this.dialogData.item.brand);
    this.form.controls.count.setValue(+this.dialogData.item.count);
    this.form.controls.comment.setValue(this.dialogData.item.comment);
    this.form.controls.timeDelivery.setValue(this.dialogData.item.timeDelivery);
    this.form.controls.siteCost.setValue(this.dialogData.item.siteCost);
    this.form.controls.priceCost.setValue(this.dialogData.item.priceCost);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  addRandomProduct(): boolean {
    if (this.form.invalid) {
      this.srv.errorMessage('Некоректно заполнена форма');
      return false;
    }

    const obj = this.form.value;

    this.rra
      .setApproveProduct(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.dialogRef.close(200));
  }

  setLoadSitePrice(): void {
    this.rra
      .getNewSiteCost(this.form.get('priceCost').value)
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((val) => {
        if (+val.code === 200) {
          this.form.get('siteCost').setValue(val.siteCost);
        }
      });
  }

  setAnalog(checked): void {
    this.form.controls.checkAnalog.setValue(checked);

    if (checked) {
      this.form.controls.katalNumberAnalog.setValidators([Validators.required]);
      this.form.controls.katalNumberAnalog.updateValueAndValidity();

      this.form.controls.brandAnalog.setValidators([Validators.required]);
      this.form.controls.brandAnalog.updateValueAndValidity();

      this.form.controls.countAnalog.setValidators([Validators.required]);
      this.form.controls.countAnalog.updateValueAndValidity();
    } else {
      this.form.controls.katalNumberAnalog.clearValidators();
      this.form.controls.katalNumberAnalog.updateValueAndValidity();

      this.form.controls.brandAnalog.clearValidators();
      this.form.controls.brandAnalog.updateValueAndValidity();

      this.form.controls.countAnalog.clearValidators();
      this.form.controls.countAnalog.updateValueAndValidity();
    }
  }

  setNotFound(): void {
    const obj = this.form.value;
    this.srv.successMessage('Товара нет в наличии');
    this.rra
      .setNotFound(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.dialogRef.close(200));
  }
}
