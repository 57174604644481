import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOption,
} from '@angular/material/core';
import { Observable, Subscription } from 'rxjs';
import {
  CallListOrder,
  OrderFooter,
  OrderList,
  OtherSpr,
  RequestBody,
} from '../../../core/interfaces/interfaces';
import { ApiService } from '../../../core/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogCountOrderComponent } from './dialog-count-order/dialog-count-order.component';
import { DialogCountTopComponent } from './dialog-count-top/dialog-count-top.component';
import { DialogSummaryComponent } from './dialog-summary/dialog-summary.component';
import { DialogMoreCallOrderComponent } from './dialog-more-call-order/dialog-more-call-order.component';
import { Title } from '@angular/platform-browser';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-count-profit',
  templateUrl: './count-profit.component.html',
  styleUrls: ['./count-profit.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'ru-Ru' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CountProfitComponent implements OnInit, OnDestroy {
  form: FormGroup;
  subscription: Subscription;
  orderList: OrderList[] = [];
  chartOrder: any;
  sourceList: OtherSpr[];
  managerList: OtherSpr[];
  carList: OtherSpr[];
  formList: OtherSpr[];
  prodGroupList: OtherSpr[];
  paymentList: OtherSpr[];
  deliveryList: OtherSpr[];
  brandList: OtherSpr[];
  cols: any[];
  loading: boolean;
  footerInfo: OrderFooter = {
    class: '',
    colspan: 0,
    consultation: 0,
    conv1: 0,
    conv2: 0,
    orderProd: 0,
    presentations: 0,
    presentationsProd: 0,
    successfulOrders: 0,
  };
  arrPie = [];

  pieData: any;
  lineData: any;
  lineDataArr = [];

  topList: Observable<any>;
  callListOrder: Observable<CallListOrder>;

  // для выбора всего списка соответсвенно
  @ViewChild('allSelectedGroup') private allSelectedGroup: MatOption;
  @ViewChild('allSelectedForm') private allSelectedForm: MatOption;
  @ViewChild('allSelectedCar') private allSelectedCar: MatOption;
  @ViewChild('allSelectedManager') private allSelectedManager: MatOption;
  @ViewChild('allSelectedSource') private allSelectedSource: MatOption;
  @ViewChild('allSelectedPayment') private allSelectedPayment: MatOption;
  @ViewChild('allSelectedDelivery') private allSelectedDelivery: MatOption;
  @ViewChild('allSelectedBrand') private allSelectedBrand: MatOption;

  constructor(
    private api: ApiService,
    public srv: SrvService,
    private dialog: MatDialog,
    private title: Title
  ) {
    this.title.setTitle('Количественная аналитика');

    this.form = new FormGroup({
      start: new FormControl(new Date(), [Validators.required]),
      end: new FormControl(new Date(), [Validators.required]),
      source: new FormControl(''),
      manager: new FormControl(''),
      car: new FormControl(''),
      formSale: new FormControl(''),
      prodGroup: new FormControl(''),
      payment: new FormControl(''),
      delivery: new FormControl(''),
      brand: new FormControl(''),
      pathSelect: new FormControl(''),
      typeFilter: new FormControl('1'),
      dataType: new FormControl('1'),
      orderDataType: new FormControl('1'),
      typeProduct: new FormControl(false),
    });
  }

  ngOnInit(): void {
    this.getDynamicSpr();
  }

  // Обновление динамических справочников
  getDynamicSpr(): void {
    const formVal = this.form.value;

    const getSourceReason: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprSourceRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getSourceReason).subscribe((spr) => {
      this.sourceList = spr;
    });

    const getManager: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprManagerRange',
      methodProperties: {
        type: 1,
        managerList: 3,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getManager).subscribe((spr) => {
      this.managerList = spr;
    });

    const getCar: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprCarRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getCar).subscribe((spr) => {
      this.carList = spr;
    });

    const getFormSale: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprFormSaleRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getFormSale).subscribe((spr) => {
      this.formList = spr;
    });

    const getProdGroup: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprProdGroupRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getProdGroup).subscribe((spr) => {
      this.prodGroupList = spr;
    });

    const getPayment: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprPaymentRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getPayment).subscribe((spr) => {
      this.paymentList = spr;
    });

    const getDelivery: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprDeliveryRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getDelivery).subscribe((spr) => {
      this.deliveryList = spr;
    });

    const getBrand: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprBrandRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getBrand).subscribe((spr) => {
      this.brandList = spr;
    });

    const getTop: RequestBody = {
      modelName: 'AnalyticsCount',
      calledMethod: 'getOrderProductTop',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
        orderDataType: formVal.orderDataType,
        typeProduct: formVal.typeProduct,
      },
    };
    this.topList = this.api.post_jwt(getTop);

    const getCallOrder: RequestBody = {
      modelName: 'AnalyticsCount',
      calledMethod: 'getCallOrderList',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.callListOrder = this.api.post_jwt(getCallOrder);

    this.callListOrder.subscribe((val) => {
      this.arrPie = [
        {
          labels: [
            val.cols[2].header,
            val.cols[4].header,
            val.cols[6].header,
            val.cols[8].header,
            val.cols[10].header,
            val.cols[12].header,
            val.cols[14].header,
            val.cols[16].header,
            val.cols[18].header,
            val.cols[20].header,
          ],
          title: val.rows[0].name,
          datasets: [
            {
              data: [
                val.rows[0].conv1,
                val.rows[0].conv2,
                val.rows[0].conv3,
                val.rows[0].conv4,
                val.rows[0].conv7,
                val.rows[0].conv8,
                val.rows[0].conv9,
                val.rows[0].conv10,
                val.rows[0].conv5,
                val.rows[0].conv6,
              ],
              backgroundColor: [
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
              ],
            },
          ],
        },
        {
          labels: [
            val.cols[2].header,
            val.cols[4].header,
            val.cols[6].header,
            val.cols[8].header,
            val.cols[10].header,
            val.cols[12].header,
            val.cols[14].header,
            val.cols[16].header,
            val.cols[18].header,
            val.cols[20].header,
          ],
          title: val.rows[1].name,
          datasets: [
            {
              data: [
                val.rows[1].conv1,
                val.rows[1].conv2,
                val.rows[1].conv3,
                val.rows[1].conv4,
                val.rows[1].conv7,
                val.rows[1].conv8,
                val.rows[1].conv9,
                val.rows[1].conv10,
                val.rows[1].conv5,
                val.rows[1].conv6,
              ],
              backgroundColor: [
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
                this.dynamicColors(),
              ],
            },
          ],
        },
      ];

      /*     this.pieData = {
             labels: labelList,
             datasets: [
               {
                 data: dataList,
                 backgroundColor: backgroundColorList
               }]
           };*/
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getOrderInfo(): boolean {
    if (this.form.invalid) {
      return false;
    }

    this.loading = true;
    const formVal = this.form.value;
    const q: RequestBody = {
      modelName: 'AnalyticsCount',
      calledMethod: 'getCountAnalytics',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
        typeFilter: formVal.typeFilter,
        source: this.deleteAllMark(formVal.source),
        formSale: this.deleteAllMark(formVal.formSale),
        manager: this.deleteAllMark(formVal.manager),
        car: this.deleteAllMark(formVal.car),
        prodGroup: this.deleteAllMark(formVal.prodGroup),
        payment: this.deleteAllMark(formVal.payment),
        delivery: this.deleteAllMark(formVal.delivery),
        brand: this.deleteAllMark(formVal.brand),
        dataType: formVal.dataType,
        orderDataType: formVal.orderDataType,
        typeProduct: formVal.typeProduct,
        pathSelect: formVal.pathSelect,
      },
    };

    this.subscription = this.api.post_jwt(q).subscribe((spr) => {
      this.cols = spr.headerList;
      this.orderList = spr.orderList;
      this.footerInfo = spr.footer;
      this.chartOrder = spr.chartOrder;
      this.loading = false;
      this.lineDataArr = [];
      const labelList = [];
      const dataList = [];
      const backgroundColorList = [];
      this.orderList.forEach((item) => {
        labelList.push(item.filter);
        dataList.push(
          this.srv.roundNumber(
            (item.successfulOrders * 100) / this.footerInfo.successfulOrders
          )
        );
        backgroundColorList.push(this.dynamicColors());
      });

      this.pieData = {
        labels: labelList,
        datasets: [
          {
            data: dataList,
            backgroundColor: backgroundColorList,
          },
        ],
      };

      const arrDateRange = this.srv.getDatesRange(
        this.srv.convertDate(formVal.start),
        this.srv.convertDate(formVal.end),
        +formVal.dataType
      );

      this.chartOrder.forEach((item) => {
        const arrOrderDate = [];
        const arrOrderList = [];
        const arrPresList = [];
        const arrConsList = [];
        item.chartOrder.forEach((val) => {});

        const orderList = new Map();
        item.chartOrder.forEach((val) => {
          orderList.set(val.date, val.count);
        });

        const pressList = new Map();
        item.chartPres.forEach((val) => {
          pressList.set(val.date, val.count);
        });
        const consList = new Map();
        item.chartCons.forEach((val) => {
          consList.set(val.date, val.count);
        });

        arrDateRange.forEach((itemDate) => {
          if (orderList.get(itemDate)) {
            arrOrderList.push(orderList.get(itemDate));
          } else {
            arrOrderList.push(0);
          }

          if (pressList.get(itemDate)) {
            arrPresList.push(pressList.get(itemDate));
          } else {
            arrPresList.push(0);
          }

          if (consList.get(itemDate)) {
            arrConsList.push(consList.get(itemDate));
          } else {
            arrConsList.push(0);
          }

          arrOrderDate.push(itemDate);
        });

        /*      item.chartPres.forEach(val => {
                arrOrderDate.push(val.date);
                arrPresList.push(val.count);
                if (orderList.get(val.date)) {
                  arrOrderList.push(orderList.get(val.date));
                } else {
                  arrOrderList.push(0);
                }


              });*/

        this.lineDataArr.push({
          labelChart: item.name,
          labels: arrOrderDate,
          datasets: [
            {
              label: 'Заказы',
              data: arrOrderList,
              fill: false,
              borderColor: '#4bc0c0',
            },
            {
              label: 'Презентации',
              data: arrPresList,
              fill: false,
              borderColor: '#565656',
            },
            {
              label: 'Консультации',
              data: arrConsList,
              fill: false,
              borderColor: '#e85757',
            },
          ],
        });
      });
    });
  }

  dynamicColors(): string {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }

  // удалить признак отметки всех
  deleteAllMark(arr): any {
    const index = arr.indexOf('all');
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  // функции выбрать все
  toggleAllGroup(): void {
    if (this.allSelectedGroup.selected) {
      this.form.controls.prodGroup.patchValue([
        ...this.prodGroupList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.prodGroup.patchValue([]);
    }
  }

  toggleAllForm(): void {
    if (this.allSelectedForm.selected) {
      this.form.controls.formSale.patchValue([
        ...this.formList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.formSale.patchValue([]);
    }
  }

  toggleAllCar(): void {
    if (this.allSelectedCar.selected) {
      this.form.controls.car.patchValue([
        ...this.carList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.car.patchValue([]);
    }
  }

  toggleAllManager(): void {
    if (this.allSelectedManager.selected) {
      this.form.controls.manager.patchValue([
        ...this.managerList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.manager.patchValue([]);
    }
  }

  toggleAllSource(): void {
    if (this.allSelectedSource.selected) {
      this.form.controls.source.patchValue([
        ...this.sourceList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.source.patchValue([]);
    }
  }

  toggleAllPayment(): void {
    if (this.allSelectedPayment.selected) {
      this.form.controls.payment.patchValue([
        ...this.paymentList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.payment.patchValue([]);
    }
  }

  toggleAllDelivery(): void {
    if (this.allSelectedDelivery.selected) {
      this.form.controls.delivery.patchValue([
        ...this.deliveryList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.delivery.patchValue([]);
    }
  }

  toggleAllBrand(): void {
    if (this.allSelectedBrand.selected) {
      this.form.controls.brand.patchValue([
        ...this.brandList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.brand.patchValue([]);
    }
  }

  showMoreInfo(col: string, rowData: OrderList): void {
    switch (col) {
      case 'conv1':
        this.srv.primaryMessage(
          'Ячейка расчитывается Кол-во успешных заказов * 100/ Кол-во консультаций'
        );
        break;
      case 'conv2':
        this.srv.primaryMessage(
          'Ячейка расчитывается Кол-во успешных заказов * 100/ Кол-во презентаций'
        );
        break;

      case 'entry':
        this.srv.primaryMessage(
          'Ячейка расчитывается Кол-во успешных заказов в строке * 100/ На все успешные заказы'
        );
        break;

      case 'filter':
        this.srv.primaryMessage('Нет данных для разворота');
        break;
      default:
        const formVal = this.form.value;
        this.dialog.open(DialogCountOrderComponent, {
          minWidth: '40%',
          maxWidth: '90%',
          maxHeight: '90vh',
          data: {
            field: col,
            filterId: rowData.filterId,
            startDate: this.srv.convertDate(formVal.start),
            endDate: this.srv.convertDate(formVal.end),
            typeFilter: formVal.typeFilter,
            orderDataType: formVal.orderDataType,
            typeProduct: formVal.typeProduct,
            pathSelect: formVal.pathSelect,
            source: this.deleteAllMark(formVal.source),
            formSale: this.deleteAllMark(formVal.formSale),
            manager: this.deleteAllMark(formVal.manager),
            car: this.deleteAllMark(formVal.car),
            prodGroup: this.deleteAllMark(formVal.prodGroup),
            payment: this.deleteAllMark(formVal.payment),
            delivery: this.deleteAllMark(formVal.delivery),
            brand: this.deleteAllMark(formVal.brand),
          },
        });
        break;
    }
  }

  showMoreInfoTop(num: number, rowData: any): void {
    this.dialog.open(DialogCountTopComponent, {
      minWidth: '40%',
      maxWidth: '90%',
      maxHeight: '90vh',
      data: {
        field: num,
        startDate: this.form.value.start,
        endDate: this.form.value.end,
        orderDataType: this.form.value.orderDataType,
        typeProduct: this.form.value.typeProduct,
      },
    });
  }

  showMoreInfoCallOrder(numberIndex: number, rowData: any): void {
    this.dialog.open(DialogMoreCallOrderComponent, {
      minWidth: '40%',
      maxWidth: '90%',
      maxHeight: '90vh',
      data: {
        field: numberIndex,
        row: rowData,
        startDate: this.form.value.start,
        endDate: this.form.value.end,
      },
    });
  }

  showSummary(): void {
    this.dialog.open(DialogSummaryComponent, {
      maxWidth: '90%',
      maxHeight: '90vh',
      minWidth: '40%',
    });
  }
}
