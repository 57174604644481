import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HeaderService } from '../../core/header.service';
import { SprList } from '../../interface';
import { SrvService } from 'repositories';
@UntilDestroy()
@Component({
  selector: 'lib-header-random-product',
  templateUrl: './header-random-product.component.html',
})
export class HeaderRandomProductComponent implements OnInit {
  title: any;
  formData: FormGroup = new FormGroup({
    id: new FormControl('', Validators.required),
    orderNumber: new FormControl('', Validators.required),
    nomen: new FormControl('', Validators.required),
    katalNumber: new FormControl('', Validators.required),
    katalNumberAnalog: new FormControl(''),
    countAnalog: new FormControl(''),
    brandAnalog: new FormControl(''),
    brand: new FormControl('', Validators.required),
    count: new FormControl(1, Validators.required),
    priceCost: new FormControl('', Validators.required),
    siteCost: new FormControl('', Validators.required),
    zakazNumber: new FormControl('', Validators.required),
    timeDelivery: new FormControl('', Validators.required),
    comment: new FormControl(''),
    commentPayment: new FormControl(''),
    checkAnalog: new FormControl(false),
  });

  countList = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    40,
  ];
  timeDeliveryList = [
    { name: '0-1', value: 1 },
    { name: '1-2', value: 2 },
    { name: '2-3', value: 3 },
    { name: '3-4', value: 4 },
    { name: 'более 4', value: 5 },
  ];

  loadProviderList$: Observable<SprList[]> = this.hs.loadProviderList();

  constructor(
    private hs: HeaderService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<HeaderRandomProductComponent>,
    private srv: SrvService
  ) {
    this.title = 'Добавить произвольный товар';
  }

  ngOnInit(): void {
    this.formData.controls.nomen.setValue(this.dialogData.item.nomen);
    this.formData.controls.katalNumber.setValue(
      this.dialogData.item.katalNumber
    );
    this.formData.controls.brand.setValue(this.dialogData.item.brand);
    this.formData.controls.id.setValue(this.dialogData.item.id);
    this.formData.controls.orderNumber.setValue(
      this.dialogData.item.orderNumber
    );
    this.formData.controls.count.setValue(+this.dialogData.item.count);
    this.formData.controls.comment.setValue(this.dialogData.item.comment);
    this.formData.controls.timeDelivery.setValue(
      this.dialogData.item.timeDelivery
    );
    this.formData.controls.siteCost.setValue(this.dialogData.item.siteCost);
    this.formData.controls.priceCost.setValue(this.dialogData.item.priceCost);
  }

  addRandomProduct(): boolean {
    if (this.formData.invalid) {
      this.srv.successMessage('Некоректно заполнена форма');
      return false;
    }

    this.hs
      .setApproveProduct(this.formData.value)
      .subscribe(() => this.dialogRef.close(200));
  }

  setLoadSitePrice(): void {
    this.hs
      .getNewSiteCost({ priceCost: this.formData.get('priceCost').value })
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((val) => {
        if (+val.code === 200) {
          this.formData.get('siteCost').setValue(val.siteCost);
        }
      });
  }

  setAnalog(): void {
    if (this.formData.value.checkAnalog) {
      this.formData.controls.katalNumberAnalog.setValidators([
        Validators.required,
      ]);
      this.formData.controls.katalNumberAnalog.updateValueAndValidity();

      this.formData.controls.brandAnalog.setValidators([Validators.required]);
      this.formData.controls.brandAnalog.updateValueAndValidity();

      this.formData.controls.countAnalog.setValidators([Validators.required]);
      this.formData.controls.countAnalog.updateValueAndValidity();
    } else {
      this.formData.controls.katalNumberAnalog.clearValidators();
      this.formData.controls.katalNumberAnalog.updateValueAndValidity();

      this.formData.controls.brandAnalog.clearValidators();
      this.formData.controls.brandAnalog.updateValueAndValidity();

      this.formData.controls.countAnalog.clearValidators();
      this.formData.controls.countAnalog.updateValueAndValidity();
    }
  }

  setNotFound(): void {
    this.srv.errorMessage('Товара нет в наличии');
    this.hs
      .setNotFound(this.formData.value)
      .subscribe(() => this.dialogRef.close(200));
  }
}
