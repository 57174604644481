import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectWithFilterComponent } from './select-with-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SelectWithFilterComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
  exports: [SelectWithFilterComponent],
})
export class SelectWithFilterModule {}
