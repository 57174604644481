import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { TaskService } from '../core/task.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-task-dialog-change-task',
  templateUrl: './task-dialog-change-task.component.html',
  styleUrls: ['./task-dialog-change-task.component.scss'],
  providers: [TaskService],
})
export class TaskDialogChangeTaskComponent implements OnInit, OnDestroy {
  form: FormGroup;
  destroy = new Subject();

  constructor(
    private task: TaskService,
    private srv: SrvService,
    private dialogRef: MatDialogRef<TaskDialogChangeTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.form = new FormGroup({
      dateEnd: new FormControl('', [Validators.required]),
      timeEnd: new FormControl('', [Validators.required]),
      comment: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {}

  changeDateTask(): any {
    if (this.form.invalid) {
      this.srv.errorMessage('Не все поля заполнены');
      return false;
    }

    const obj = {
      id: this.dialogData.id,
      comment: this.form.value.comment,
      dateEnd: this.form.value.dateEnd,
      timeEnd: this.form.value.timeEnd,
    };

    this.task
      .changeDateTask(obj)
      .pipe(takeUntil(this.destroy))
      .subscribe((val) => {
        if (+val.code === 200) {
          this.dialogRef.close({ code: 200 });

          this.srv.successMessage('Задача перенесена');
        }
      });
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }
}
