import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import {
  GrossFooter,
  GrossList,
  OrderList,
  OtherSpr,
  RequestBody,
} from '@core/interfaces/interfaces';
import { ApiService } from '@core/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatOption } from '@angular/material/core';
import { DialogGrossInfoComponent } from './dialog-gross-info/dialog-gross-info.component';
import { DialogGrossTopComponent } from './dialog-gross-top/dialog-gross-top.component';
import { DialogGrossSummaryComponent } from './dialog-summary/dialog-gross-summary.component';
import { Title } from '@angular/platform-browser';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-gross-page',
  templateUrl: './gross-page.component.html',
  styleUrls: ['./gross-page.component.scss'],
})
export class GrossPageComponent implements OnInit, OnDestroy {
  form: FormGroup;
  subscription: Subscription;
  orderList: GrossList[] = [];
  chartOrder: any;
  sourceList: OtherSpr[];
  managerList: OtherSpr[];
  carList: OtherSpr[];
  formList: OtherSpr[];
  prodGroupList: OtherSpr[];
  paymentList: OtherSpr[];
  deliveryList: OtherSpr[];
  brandList: OtherSpr[];
  cols: any[];
  loading: boolean;
  footerInfo: GrossFooter = {
    allSale: 0,
    button: undefined,
    class: '',
    colspan: 0,
    conv1: 0,
    entry: 0,
    filter: '',
    grossSale: 0,
    middleSale: 0,
    middleSaleMore: 0,
    middleSaleOne: 0,
    saleMore: 0,
    saleOne: 0,
  };

  pieData: any;
  lineData: any;
  lineDataArr = [];

  topList: Observable<any>;

  // для выбора всего списка соответсвенно
  @ViewChild('allSelectedGroup') private allSelectedGroup: MatOption;
  @ViewChild('allSelectedForm') private allSelectedForm: MatOption;
  @ViewChild('allSelectedCar') private allSelectedCar: MatOption;
  @ViewChild('allSelectedManager') private allSelectedManager: MatOption;
  @ViewChild('allSelectedSource') private allSelectedSource: MatOption;
  @ViewChild('allSelectedPayment') private allSelectedPayment: MatOption;
  @ViewChild('allSelectedDelivery') private allSelectedDelivery: MatOption;
  @ViewChild('allSelectedBrand') private allSelectedBrand: MatOption;

  constructor(
    private api: ApiService,
    public srv: SrvService,
    private dialog: MatDialog,
    private title: Title
  ) {
    this.title.setTitle('Финансовая аналитика');

    this.form = new FormGroup({
      start: new FormControl(new Date(), [Validators.required]),
      end: new FormControl(new Date(), [Validators.required]),
      source: new FormControl(''),
      manager: new FormControl(''),
      car: new FormControl(''),
      formSale: new FormControl(''),
      prodGroup: new FormControl(''),
      payment: new FormControl(''),
      delivery: new FormControl(''),
      brand: new FormControl(''),
      typeFilter: new FormControl('1'),
      dataType: new FormControl('1'),
      typeProduct: new FormControl(false),
      orderDataType: new FormControl('1'),
    });
  }

  ngOnInit(): void {
    this.getDynamicSpr();
  }

  // Обновление динамических справочников
  getDynamicSpr(): void {
    const formVal = this.form.value;

    const getSourceReason: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprSourceRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getSourceReason).subscribe((spr) => {
      this.sourceList = spr;
    });

    const getManager: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprManagerRange',
      methodProperties: {
        type: 1,
        managerList: 3,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getManager).subscribe((spr) => {
      this.managerList = spr;
    });

    const getCar: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprCarRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getCar).subscribe((spr) => {
      this.carList = spr;
    });

    const getFormSale: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprFormSaleRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getFormSale).subscribe((spr) => {
      this.formList = spr;
    });

    const getProdGroup: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprProdGroupRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getProdGroup).subscribe((spr) => {
      this.prodGroupList = spr;
    });

    const getPayment: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprPaymentRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getPayment).subscribe((spr) => {
      this.paymentList = spr;
    });

    const getDelivery: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprDeliveryRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getDelivery).subscribe((spr) => {
      this.deliveryList = spr;
    });

    const getBrand: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprBrandRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getBrand).subscribe((spr) => {
      this.brandList = spr;
    });

    const getTop: RequestBody = {
      modelName: 'AnalyticsGross',
      calledMethod: 'getGrossProductTop',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
        orderDataType: formVal.orderDataType,
        typeProduct: formVal.typeProduct,
      },
    };
    this.topList = this.api.post_jwt(getTop);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getOrderInfo(): boolean {
    if (this.form.invalid) {
      return false;
    }

    this.loading = true;
    const formVal = this.form.value;
    const q: RequestBody = {
      modelName: 'AnalyticsGross',
      calledMethod: 'getGrossAnalytics',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
        typeFilter: formVal.typeFilter,
        typeProduct: formVal.typeProduct,
        source: this.deleteAllMark(formVal.source),
        formSale: this.deleteAllMark(formVal.formSale),
        manager: this.deleteAllMark(formVal.manager),
        car: this.deleteAllMark(formVal.car),
        prodGroup: this.deleteAllMark(formVal.prodGroup),
        payment: this.deleteAllMark(formVal.payment),
        delivery: this.deleteAllMark(formVal.delivery),
        brand: this.deleteAllMark(formVal.brand),
        dataType: formVal.dataType,
        orderDataType: formVal.orderDataType,
      },
    };

    this.subscription = this.api.post_jwt(q).subscribe((spr) => {
      this.cols = spr.headerList;
      this.orderList = spr.orderList;
      this.footerInfo = spr.footer;
      this.chartOrder = spr.chartOrder;
      this.loading = false;
      this.lineDataArr = [];
      const labelList = [];
      const dataList = [];
      const backgroundColorList = [];
      this.orderList.forEach((item) => {
        labelList.push(item.filter);
        dataList.push(
          this.srv.roundNumber((item.allSale * 100) / this.footerInfo.allSale)
        );
        backgroundColorList.push(this.dynamicColors());
      });

      this.pieData = {
        labels: labelList,
        datasets: [
          {
            data: dataList,
            backgroundColor: backgroundColorList,
          },
        ],
      };

      const arrDateRange = this.srv.getDatesRange(
        this.srv.convertDate(formVal.start),
        this.srv.convertDate(formVal.end),
        +formVal.dataType
      );

      this.chartOrder.forEach((item) => {
        const arrOrderDate = [];
        const arrSaleList = [];
        const arrMiddleList = [];
        const arrGrossList = [];
        /*
        item.orderChart.forEach(val => {

          arrOrderDate.push(val.date);
          arrSaleList.push(val.sale);
          arrGrossList.push(val.gross);


        });
*/

        const saleList = new Map();
        item.orderChart.forEach((val) => {
          saleList.set(val.date, val.sale);
        });

        const grossList = new Map();
        item.orderChart.forEach((val) => {
          grossList.set(val.date, val.gross);
        });

        const middleList = new Map();
        item.orderChart.forEach((val) => {
          middleList.set(val.date, val.middle);
        });

        arrDateRange.forEach((itemDate) => {
          if (saleList.get(itemDate)) {
            arrSaleList.push(saleList.get(itemDate));
          } else {
            arrSaleList.push(0);
          }

          if (grossList.get(itemDate)) {
            arrGrossList.push(grossList.get(itemDate));
          } else {
            arrGrossList.push(0);
          }

          if (middleList.get(itemDate)) {
            arrMiddleList.push(middleList.get(itemDate));
          } else {
            arrMiddleList.push(0);
          }

          arrOrderDate.push(itemDate);
        });

        this.lineDataArr.push({
          labelChart: item.name,
          labels: arrOrderDate,
          datasets: [
            {
              label: 'Объем сбыта',
              data: arrSaleList,
              fill: false,
              borderColor: '#4bc0c0',
            },
            {
              label: ' Валовая прибыль',
              data: arrGrossList,
              fill: false,
              borderColor: '#565656',
            },

            {
              label: 'Средний чек',
              data: arrMiddleList,
              fill: false,
              borderColor: '#ec6565',
            },
          ],
        });
      });
    });
  }

  dynamicColors(): string {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }

  // удалить признак отметки всех
  deleteAllMark(arr): any {
    const index = arr.indexOf('all');
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  // функции выбрать все
  toggleAllGroup(): void {
    if (this.allSelectedGroup.selected) {
      this.form.controls.prodGroup.patchValue([
        ...this.prodGroupList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.prodGroup.patchValue([]);
    }
  }

  toggleAllForm(): void {
    if (this.allSelectedForm.selected) {
      this.form.controls.formSale.patchValue([
        ...this.formList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.formSale.patchValue([]);
    }
  }

  toggleAllCar(): void {
    if (this.allSelectedCar.selected) {
      this.form.controls.car.patchValue([
        ...this.carList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.car.patchValue([]);
    }
  }

  toggleAllManager(): void {
    if (this.allSelectedManager.selected) {
      this.form.controls.manager.patchValue([
        ...this.managerList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.manager.patchValue([]);
    }
  }

  toggleAllSource(): void {
    if (this.allSelectedSource.selected) {
      this.form.controls.source.patchValue([
        ...this.sourceList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.source.patchValue([]);
    }
  }

  toggleAllPayment(): void {
    if (this.allSelectedPayment.selected) {
      this.form.controls.payment.patchValue([
        ...this.paymentList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.payment.patchValue([]);
    }
  }

  toggleAllDelivery(): void {
    if (this.allSelectedDelivery.selected) {
      this.form.controls.delivery.patchValue([
        ...this.deliveryList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.delivery.patchValue([]);
    }
  }

  toggleAllBrand(): void {
    if (this.allSelectedBrand.selected) {
      this.form.controls.brand.patchValue([
        ...this.brandList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.brand.patchValue([]);
    }
  }

  showMoreInfo(col: string, rowData: OrderList): void {
    switch (col) {
      case 'conv1':
        this.srv.primaryMessage(
          'Ячейка расчитывается Кол-во успешных заказов * 100/ Кол-во консультаций'
        );
        break;

      case 'entry':
        this.srv.primaryMessage(
          'Ячейка расчитывается Кол-во успешных заказов в строке * 100/ На все успешные заказы'
        );
        break;

      case 'filter':
        this.srv.primaryMessage('Нет данных для разворота');
        break;
      default:
        const formVal = this.form.value;
        this.dialog.open(DialogGrossInfoComponent, {
          minWidth: '40%',
          maxWidth: '90%',
          maxHeight: '90vh',
          data: {
            field: col,
            filterId: rowData.filterId,
            startDate: this.srv.convertDate(formVal.start),
            endDate: this.srv.convertDate(formVal.end),
            typeFilter: formVal.typeFilter,
            typeProduct: formVal.typeProduct,
            source: this.deleteAllMark(formVal.source),
            formSale: this.deleteAllMark(formVal.formSale),
            manager: this.deleteAllMark(formVal.manager),
            car: this.deleteAllMark(formVal.car),
            prodGroup: this.deleteAllMark(formVal.prodGroup),
            payment: this.deleteAllMark(formVal.payment),
            delivery: this.deleteAllMark(formVal.delivery),
            brand: this.deleteAllMark(formVal.brand),
            orderDataType: formVal.orderDataType,
          },
        });
        break;
    }
  }

  showMoreInfoTop(numberOrder: number, rowData: any): void {
    this.dialog.open(DialogGrossTopComponent, {
      minWidth: '40%',
      maxWidth: '90%',
      maxHeight: '90vh',
      data: {
        field: numberOrder,
        startDate: this.form.value.start,
        endDate: this.form.value.end,
        orderDataType: this.form.value.orderDataType,
        typeProduct: this.form.value.typeProduct,
      },
    });
  }

  showSummary(): void {
    this.dialog.open(DialogGrossSummaryComponent, {
      maxWidth: '90%',
      maxHeight: '90vh',
      minWidth: '40%',
    });
  }
}
