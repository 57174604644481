import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { ClsService } from '../service/cls.service';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { OtherSpr } from '../../../../core/interfaces/interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-call-list-create-order',
  templateUrl: './call-list-create-order.component.html',
  styleUrls: ['./call-list-create-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ClsService],
})
export class CallListCreateOrderComponent implements OnInit {
  userList: FormControl;
  userListArr$: Observable<OtherSpr[]>;

  constructor(
    private cls: ClsService,
    private srv: SrvService,
    private dialogRef: MatDialogRef<CallListCreateOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.userList = new FormControl('', [Validators.required]);
  }

  ngOnInit(): void {
    this.loadUserList();
  }

  loadUserList(): void {
    this.userListArr$ = this.cls.loadUserList();
  }

  onCreateNewOrder(): any {
    if (this.userList.invalid) {
      this.srv.errorMessage('Выберите сотрудника');
      return false;
    }

    this.cls
      .onCreateNewOrder(this.dialogData.hash, this.userList.value)
      .subscribe((val) => {
        this.srv.successMessage('Заказ создан');

        this.dialogRef.close();
      });
  }
}
