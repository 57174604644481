import { Component, Inject, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ChatList } from '../core/chat';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { OrderChatComponent } from '../order-chat/order-chat.component';
import { ChatService } from '../core/chat.service';
import { OrderResponseComponent } from '../order-response/order-response.component';
import { WarehouseChatComponent } from '../warehouse-chat/warehouse-chat.component';
import { SrvService } from 'repositories';

@Component({
  selector: 'lib-order-chat-list',
  templateUrl: './order-chat-list.component.html',
  providers: [ChatService],
})
export class OrderChatListComponent implements OnInit {
  requestList$: Observable<ChatList[]>;
  requestListArchive$: Observable<ChatList[]>;
  slideRequest: boolean;
  permUser: number[];
  idProd: number;
  @Input() prodId?: number;
  @Input() src = false;
  constructor(
    private dialog: MatDialog,
    private srv: SrvService,
    private chat: ChatService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.permUser = this.srv.permission$
      .getValue()
      .permission.filter((val) => +val === 38);
  }

  ngOnInit(): void {
    this.loadRequestList();
    this.loadRequestListArchive();
  }

  onCreateNewRequest(): void {
    const dialog = this.dialog.open(OrderChatComponent, {
      data: {
        id: this.dialogData.id,
        katalNumber: this.dialogData.katalNumber,
        brand: this.dialogData.brand,
      },
      maxHeight: '94vh',
      minWidth: '40vw',
    });

    dialog.afterClosed().subscribe((val) => {
      if (+val.code === 200) {
        this.loadRequestList();
        this.loadRequestListArchive();
      }
    });
  }

  onCreateNewRequestWarehouse(): void {
    const dialog = this.dialog.open(WarehouseChatComponent, {
      data: {
        id: this.dialogData.id,
        katalNumber: this.dialogData.katalNumber,
        brand: this.dialogData.brand,
      },
      maxHeight: '94vh',
      minWidth: '50vw',
    });

    dialog.afterClosed().subscribe((val) => {
      if (+val.code === 200) {
        this.loadRequestList();
      }
    });
  }

  loadRequestList(): void {
    this.requestList$ = this.chat.loadRequestList(this.dialogData.id);
  }

  loadRequestListArchive(): void {
    this.requestListArchive$ = this.chat.loadRequestListArchive(
      this.dialogData.id
    );
  }

  setResponse(num: number, item): void {
    const dialog = this.dialog.open(OrderResponseComponent, {
      data: {
        num,
        item,
      },
      maxHeight: '94vh',
      minWidth: '50vw',
    });

    dialog.afterClosed().subscribe((val) => {
      if (+val.code === 200) {
        this.loadRequestList();
      }
    });
  }

  closeRequest(num: any, orderNumber?: any, date?: string): void {
    if (orderNumber && date) {
    } else {
      this.chat.closeRequest(num).subscribe(() => {
        this.loadRequestList();

        this.srv.successMessage('Запрос перемещен в архив');
      });
    }
  }
}
