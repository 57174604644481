import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOption,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import {
  OrderList,
  OtherSpr,
  RequestBody,
  ReturnProductFooter,
  ReturnProductList,
} from '../../../core/interfaces/interfaces';
import { ApiService } from '../../../core/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogMoreInfoComponent } from './dialog-more-info/dialog-more-info.component';
import { DialogMoreTopComponent } from './dialog-more-top/dialog-more-top.component';
import { DialogProductOnStockComponent } from './dialog-product-on-stock/dialog-product-on-stock.component';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-return-page',
  templateUrl: './return-page.component.html',
  styleUrls: ['./return-page.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'ru-Ru' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class ReturnPageComponent implements OnInit, OnDestroy {
  @ViewChild('allSelectedGroup') private allSelectedGroup: MatOption;
  @ViewChild('allSelectedForm') private allSelectedForm: MatOption;
  @ViewChild('allSelectedCar') private allSelectedCar: MatOption;
  @ViewChild('allSelectedManager') private allSelectedManager: MatOption;
  @ViewChild('allSelectedSource') private allSelectedSource: MatOption;
  @ViewChild('allSelectedPayment') private allSelectedPayment: MatOption;
  @ViewChild('allSelectedDelivery') private allSelectedDelivery: MatOption;
  @ViewChild('allSelectedBrand') private allSelectedBrand: MatOption;
  @ViewChild('allSelectedReason') private allSelectedReason: MatOption;

  form: FormGroup;
  subscription: Subscription;
  orderList: ReturnProductList[] = [];
  chartOrder: any;
  returnReasonList: OtherSpr[];
  sourceList: OtherSpr[];
  managerList: OtherSpr[];
  carList: OtherSpr[];
  formList: OtherSpr[];
  prodGroupList: OtherSpr[];
  paymentList: OtherSpr[];
  deliveryList: OtherSpr[];
  brandList: OtherSpr[];
  cols: any[];
  loading: boolean;
  footerInfo: ReturnProductFooter = {
    allOrder: 0,
    allProduct: 0,
    allReturnOrder: 0,
    allReturnProduct: 0,
    allUniqProduct: 0,
    allUniqReturnProduct: 0,
    allSale: 0,
    allCostSite: 0,
    allCostPrice: 0,
    entry: 0,
    class: '',
  };

  pieData: any;
  lineData: any;
  lineDataArr = [];

  topList: Observable<any>;

  constructor(
    private api: ApiService,
    public srv: SrvService,
    private dialog: MatDialog
  ) {
    this.form = new FormGroup({
      start: new FormControl(new Date(), [Validators.required]),
      end: new FormControl(new Date(), [Validators.required]),
      source: new FormControl(''),
      manager: new FormControl(''),
      car: new FormControl(''),
      formSale: new FormControl(''),
      prodGroup: new FormControl(''),
      payment: new FormControl(''),
      delivery: new FormControl(''),
      brand: new FormControl(''),
      typeFilter: new FormControl('1'),
      dataType: new FormControl('1'),
      reason: new FormControl(''),
      orderDataType: new FormControl('1'),
    });
  }

  ngOnInit(): void {
    this.getDynamicSpr();
  }

  getDynamicSpr(): void {
    const formVal = this.form.value;

    const getReturnReason: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprOtherSpr',
      methodProperties: {
        type: 1,
        listId: 28,
      },
    };
    this.subscription = this.api.post_jwt(getReturnReason).subscribe((spr) => {
      this.returnReasonList = spr;
    });

    const getSourceReason: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprSourceRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getSourceReason).subscribe((spr) => {
      this.sourceList = spr;
    });

    const getManager: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprManagerRange',
      methodProperties: {
        type: 1,
        managerList: 3,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getManager).subscribe((spr) => {
      this.managerList = spr;
    });

    const getCar: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprCarRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getCar).subscribe((spr) => {
      this.carList = spr;
    });

    const getFormSale: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprFormSaleRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getFormSale).subscribe((spr) => {
      this.formList = spr;
    });

    const getProdGroup: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprProdGroupRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getProdGroup).subscribe((spr) => {
      this.prodGroupList = spr;
    });

    const getPayment: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprPaymentRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getPayment).subscribe((spr) => {
      this.paymentList = spr;
    });

    const getDelivery: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprDeliveryRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getDelivery).subscribe((spr) => {
      this.deliveryList = spr;
    });

    const getBrand: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprBrandRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getBrand).subscribe((spr) => {
      this.brandList = spr;
    });

    const getTop: RequestBody = {
      modelName: 'AnalyticsReturn',
      calledMethod: 'getReturnProductTop',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
        orderDataType: formVal.orderDataType,
      },
    };

    this.topList = this.api.post_jwt(getTop);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getOrderInfo(): any {
    if (this.form.invalid) {
      return false;
    }

    this.loading = true;
    const formVal = this.form.value;
    const q: RequestBody = {
      modelName: 'AnalyticsReturn',
      calledMethod: 'getReturnProduct',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
        typeFilter: formVal.typeFilter,
        source: this.deleteAllMark(formVal.source),
        formSale: this.deleteAllMark(formVal.formSale),
        manager: this.deleteAllMark(formVal.manager),
        car: this.deleteAllMark(formVal.car),
        prodGroup: this.deleteAllMark(formVal.prodGroup),
        payment: this.deleteAllMark(formVal.payment),
        delivery: this.deleteAllMark(formVal.delivery),
        brand: this.deleteAllMark(formVal.brand),
        reason: this.deleteAllMark(formVal.reason),
        dataType: formVal.dataType,
        orderDataType: formVal.orderDataType,
      },
    };

    this.subscription = this.api.post_jwt(q).subscribe((spr) => {
      this.cols = spr.headerList;
      this.orderList = spr.orderList;
      this.footerInfo = spr.footer;
      this.chartOrder = spr.chartOrder;
      this.loading = false;
      this.lineDataArr = [];
      const labelList = [];
      const dataList = [];
      const backgroundColorList = [];
      this.orderList.forEach((item) => {
        labelList.push(item.filter);
        dataList.push(
          this.srv.roundNumber(
            (item.allUniqReturnProduct * 100) /
              this.footerInfo.allUniqReturnProduct
          )
        );
        backgroundColorList.push(this.dynamicColors());
      });

      this.pieData = {
        labels: labelList,
        datasets: [
          {
            data: dataList,
            backgroundColor: backgroundColorList,
          },
        ],
      };

      const arrDateRange = this.srv.getDatesRange(
        this.srv.convertDate(formVal.start),
        this.srv.convertDate(formVal.end),
        +formVal.dataType
      );

      this.chartOrder.forEach((item) => {
        const arrOrderDate = [];
        const arrOrderList = [];
        const arrReturnList = [];
        item.chartOrder.forEach((val) => {});

        const orderReturn = new Map();
        item.chartReturn.forEach((val) => {
          orderReturn.set(val.date, val.count);
        });

        const orderList = new Map();
        item.chartOrder.forEach((val) => {
          orderList.set(val.date, val.count);
        });

        arrDateRange.forEach((itemDate) => {
          if (orderReturn.get(itemDate)) {
            arrReturnList.push(orderReturn.get(itemDate));
          } else {
            arrReturnList.push(0);
          }
          if (orderList.get(itemDate)) {
            arrOrderList.push(orderList.get(itemDate));
          } else {
            arrOrderList.push(0);
          }

          arrOrderDate.push(itemDate);
        });

        /*   item.chartOrder.forEach(val => {
          arrOrderDate.push(val.date);
          arrOrderList.push(val.count);
          if (orderReturn.get(val.date)) {
            arrReturnList.push(orderReturn.get(val.date));
          } else {
            arrReturnList.push(0);
          }


        });*/

        this.lineDataArr.push({
          labelChart: item.name,
          labels: arrOrderDate,
          datasets: [
            {
              label: 'Активные товары',
              data: arrOrderList,
              fill: false,
              borderColor: '#4bc0c0',
            },
            {
              label: 'Возврат товаров',
              data: arrReturnList,
              fill: false,
              borderColor: '#565656',
            },
          ],
        });
      });
    });
  }

  dynamicColors(): string {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }

  // удалить признак отметки всех
  deleteAllMark(arr): any {
    const index = arr.indexOf('all');
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  // функции выбрать все
  toggleAllGroup(): void {
    if (this.allSelectedGroup.selected) {
      this.form.controls.prodGroup.patchValue([
        ...this.prodGroupList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.prodGroup.patchValue([]);
    }
  }

  toggleAllForm(): void {
    if (this.allSelectedForm.selected) {
      this.form.controls.formSale.patchValue([
        ...this.formList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.formSale.patchValue([]);
    }
  }

  toggleAllCar(): void {
    if (this.allSelectedCar.selected) {
      this.form.controls.car.patchValue([
        ...this.carList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.car.patchValue([]);
    }
  }

  toggleAllManager(): void {
    if (this.allSelectedManager.selected) {
      this.form.controls.manager.patchValue([
        ...this.managerList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.manager.patchValue([]);
    }
  }

  toggleAllSource(): void {
    if (this.allSelectedSource.selected) {
      this.form.controls.source.patchValue([
        ...this.sourceList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.source.patchValue([]);
    }
  }

  toggleAllPayment(): void {
    if (this.allSelectedPayment.selected) {
      this.form.controls.payment.patchValue([
        ...this.paymentList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.payment.patchValue([]);
    }
  }

  toggleAllDelivery(): void {
    if (this.allSelectedDelivery.selected) {
      this.form.controls.delivery.patchValue([
        ...this.deliveryList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.delivery.patchValue([]);
    }
  }

  toggleAllBrand(): void {
    if (this.allSelectedBrand.selected) {
      this.form.controls.brand.patchValue([
        ...this.brandList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.brand.patchValue([]);
    }
  }

  showMoreInfo(col: string, rowData: OrderList): void {
    switch (col) {
      case 'conv1':
        this.srv.primaryMessage(
          'Ячейка расчитывается Кол-во Заказов с возвратами * 100/ Кол-во активных заказов'
        );
        break;

      case 'conv2':
        this.srv.primaryMessage(
          'Ячейка расчитывается Кол-во возвратных товаров * 100/ Кол-во активных  товаров'
        );
        break;

      case 'conv3':
        this.srv.primaryMessage(
          'Ячейка расчитывается Кол-во уникальных возвратных товаров * 100/ Кол-во уникальных активных  товаров'
        );
        break;

      case 'entry':
        this.srv.primaryMessage(
          'Ячейка расчитывается Кол-во возвратных товаров * 100/ Кол-во всех возвратных товаров'
        );
        break;

      case 'filter':
      case 'allReturnProductCost':
        this.srv.primaryMessage('Нет данных для разворота');
        break;
      default:
        const formVal = this.form.value;
        this.dialog.open(DialogMoreInfoComponent, {
          minWidth: '40%',
          maxWidth: '90%',
          maxHeight: '90vh',
          data: {
            fieldName: col,
            filterId: rowData.filterId,
            startDate: this.srv.convertDate(formVal.start),
            endDate: this.srv.convertDate(formVal.end),
            typeFilter: formVal.typeFilter,
            source: this.deleteAllMark(formVal.source),
            formSale: this.deleteAllMark(formVal.formSale),
            manager: this.deleteAllMark(formVal.manager),
            car: this.deleteAllMark(formVal.car),
            prodGroup: this.deleteAllMark(formVal.prodGroup),
            payment: this.deleteAllMark(formVal.payment),
            delivery: this.deleteAllMark(formVal.delivery),
            brand: this.deleteAllMark(formVal.brand),
            reason: this.deleteAllMark(formVal.reason),
            orderDataType: formVal.orderDataType,
          },
        });
        break;
    }
  }

  showMoreInfoTop(numberField: number, rowData: any): void {
    this.dialog.open(DialogMoreTopComponent, {
      minWidth: '40%',
      maxWidth: '90%',
      maxHeight: '90vh',
      data: {
        field: numberField,
        startDate: this.form.value.start,
        endDate: this.form.value.end,
        orderDataType: this.form.value.orderDataType,
      },
    });
  }

  toggleAllReason(): void {
    if (this.allSelectedReason.selected) {
      this.form.controls.reason.patchValue([
        ...this.returnReasonList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.reason.patchValue([]);
    }
  }

  onShowProductOnStock(): void {
    this.dialog.open(DialogProductOnStockComponent, {
      minWidth: '40%',
      maxWidth: '90%',
      maxHeight: '90vh',
      data: {
        startDate: this.form.value.start,
        endDate: this.form.value.end,
      },
    });
  }
}
