import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SudService } from '../sud.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-dialog-add-payment',
  templateUrl: './dialog-add-payment.component.html',
  styleUrls: ['./dialog-add-payment.component.scss'],
  providers: [SudService],
})
export class DialogAddPaymentComponent implements OnInit, OnDestroy {
  form: FormGroup;
  private destroy = new Subject<any>();

  constructor(
    private srv: SrvService,
    private sub: SudService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<DialogAddPaymentComponent>
  ) {
    this.form = new FormGroup({
      typePayment: new FormControl('1', [Validators.required]),
      valuePayment: new FormControl('', [Validators.required]),
      defaultPayment: new FormControl(),
    });
  }

  ngOnInit(): void {}

  addNewPayment(): boolean {
    if (this.form.invalid) {
      this.srv.errorMessage('Не все поля заполнены корректно');
      return false;
    }

    this.sub
      .addNewPayment(
        this.form.value.typePayment,
        this.form.value.valuePayment,
        this.form.value.defaultPayment,
        this.dialogData.suId
      )
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при добавлении реквизитов. Обратитесь в техподдержку'
          );
          return of('Error in method onInit addNewPayment ' + err);
        }),
        takeUntil(this.destroy)
      )
      .subscribe((val) => {
        this.dialogRef.close(val);
        this.srv.successMessage('Реквизиты добавлены');
      });
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }
}
