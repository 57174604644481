import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UclService } from '../../core/ucl.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { OtherSpr } from '../../../../../core/interfaces/interfaces';
import { filter, takeUntil } from 'rxjs/operators';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-uc-dialog-edit-user-info',
  templateUrl: './uc-dialog-edit-user-info.component.html',
  styleUrls: ['./uc-dialog-edit-user-info.component.scss'],
  providers: [UclService],
})
export class UcDialogEditUserInfoComponent implements OnInit, OnDestroy {
  formUser: FormGroup;
  typeList$: Observable<OtherSpr[]>;
  specInfoList$: Observable<OtherSpr[]>;
  managerList$: Observable<OtherSpr[]>;
  unitList$: Observable<OtherSpr[]>;
  sexList$: Observable<OtherSpr[]>;
  actionList$: Observable<OtherSpr[]>;
  cashBackList$: Observable<OtherSpr[]>;
  destroy$ = new Subject();
  editMainPhone: number[];
  constructor(
    private ucl: UclService,
    private srv: SrvService,
    private dialogRef: MatDialogRef<UcDialogEditUserInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.formUser = new FormGroup({
      phone: new FormControl(this.dialogData.phone, [
        Validators.required,
        Validators.minLength(10),
      ]),
      phoneDop: new FormControl(this.dialogData.phoneDop, [
        Validators.minLength(10),
      ]),
      name: new FormControl(this.dialogData.name, [Validators.required]),
      lastName: new FormControl(this.dialogData.lastName, [
        Validators.required,
      ]),
      middleName: new FormControl(this.dialogData.middleName),
      viber: new FormControl(this.dialogData.viber),
      viberDop: new FormControl(this.dialogData.viberDop),
      email: new FormControl(this.dialogData.email),
      type: new FormControl(this.dialogData.type),
      sex: new FormControl(this.dialogData.sex),
      badType: new FormControl(this.dialogData.badType),
      manager: new FormControl(this.dialogData.manager),
      unit: new FormControl(this.dialogData.unit),
      action: new FormControl(this.dialogData.action),
      cashBackRate: new FormControl(this.dialogData.cashBackRate),
    });

    this.srv.userPerm
      .pipe(filter((val) => val != null))
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.editMainPhone = value.permission.filter((val) => +val === 54);
      });
  }

  ngOnInit(): void {
    this.loadTypeUser();
    this.loadSpecInfoUser();
  }

  loadTypeUser(): void {
    this.typeList$ = this.ucl.loadTypeUser();
    this.managerList$ = this.ucl.managerList();
    this.unitList$ = this.ucl.unitList();
    this.sexList$ = this.ucl.sexList();
    this.actionList$ = this.ucl.actionList();
    this.cashBackList$ = this.ucl.cashBackList();
  }

  loadSpecInfoUser(): void {
    this.specInfoList$ = this.ucl.loadSpecInfoUser();
  }

  editUser(): any {
    if (this.formUser.invalid) {
      this.srv.successMessage('Не верно заполнена форма');

      return false;
    }

    const q = {
      phone: this.formUser.value.phone,
      phoneDop: this.formUser.value.phoneDop,
      name: this.formUser.value.name,
      lastName: this.formUser.value.lastName,
      middleName: this.formUser.value.middleName,
      viber: this.formUser.value.viber,
      viberDop: this.formUser.value.viberDop,
      email: this.formUser.value.email,
      sex: this.formUser.value.sex,
      typeId: this.formUser.value.type,
      hash: this.dialogData.hash,
      badType: this.formUser.value.badType,
      unit: this.formUser.value.unit,
      action: this.formUser.value.action,
      manager: this.formUser.value.manager,
      cashBackRate: this.formUser.value.cashBackRate,
    };

    this.ucl.editUser(q).subscribe((res) => {
      this.dialogRef.close({ result: res.code });
    });
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
