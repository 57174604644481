<input
  *ngIf="iFilterMainItem.length > defaultFilterLength"
  [formControl]="filterInfo"
  [placeholder]="'Поиск'"
/>
<div
  *ngFor="let val of filtersList | slice : startShowFilter : endShowFilter"
  class="my-2 my-md-0"
>
  <mat-checkbox
    color="primary"
    [checked]="checked(filterForm?.get(control)?.value, val.value)"
    (change)="onCheckChangeBrand($event, val.value, control)"
  >
    {{ val.name }}
    <ng-container *ngIf="val?.flag">
      &nbsp;
      <img
        *ngIf="val?.flag"
        [src]="val?.flag"
        alt="brand"
        width="15"
        height="15"
      />
    </ng-container>
  </mat-checkbox>
</div>
<div
  *ngIf="
    filtersList.length > defaultFilterLength &&
    filtersList.length > endShowFilter
  "
  (click)="showMoreFilter(filtersList.length)"
  class="link my-2"
>
  Показать
</div>
<div
  *ngIf="filtersList.length == endShowFilter"
  (click)="showMoreFilter(defaultFilterLength)"
  class="link my-2"
>
  Спрятать
</div>
