import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-applicability-car-info',
  templateUrl: './dialog-applicability-car-info.component.html',
  styleUrls: ['./dialog-applicability-car-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogApplicabilityCarInfoComponent implements OnInit {
  displayedColumns: string[] = ['model', 'mod', 'years']; // Массив заголовков в таблице
  nowDate = Date.now();
  sortListCar;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataObj: any // Инжектирование Переменной для приема данных из вне, Объект
  ) {}

  ngOnInit(): void {
    this.sortListCar = this.dataObj.list.filter((item) =>
      item.marka === this.dataObj.mark && item.model === this.dataObj.model
        ? item
        : ''
    );
  }
}
