export enum ApiRequest {
  CLASS = 'Server',
  METHOD_LOAD_MENU = 'getCrmMenu',
  METHOD_LOAD_DIC_INFO = 'loadDicInfo',
  METHOD_LOAD_PRETENSION = 'loadPretension',
  METHOD_LOAD_ORDER_LIST = 'loadOrderList',
  METHOD_LOAD_FAST_PRODUCT = 'loadFastProduct',
  METHOD_ADD_PRODUCT_TO_ORDER = 'addProductToOrder',
  METHOD_LOAD_MARK_PROTECTION = 'loadMarkProtection',
  METHOD_LOAD_HELP_INFO = 'loadHelpInfo',
  METHOD_SEARCH_CLIENT_INFO = 'searchClientInfo',
  METHOD_CREATE_NEW_CLIENT = 'createNewClient',
  METHOD_LOAD_TASK_FOR_ME = 'loadTaskForMe',
  METHOD_LOAD_TASK_FROM_ME = 'loadTaskFromMe',
  METHOD_LOAD_REQUEST = 'loadRequest',
  METHOD_SET_TASK_TO_WORK = 'setTaskToWork',
  METHOD_SET_TASK_TO_ARCHIVE = 'setTaskToArchive',
  METHOD_LOAD_RANDOM_PROD = 'loadRandomProd',
  METHOD_GET_NEW_SITE_COST = 'getNewSiteCost',
  METHOD_LOAD_PROVIDER_LIST = 'loadProviderList',
  METHOD_LOAD_FAST_PRODUCT_LIST = 'loadFastProductList',
}

export enum Request_PAYMENT {
  CLASS = 'PaymentProduct',
  METHOD_GET_NEW_SITE_COST = 'getNewSiteCost',
  METHOD_SET_NOT_FOUND_RANDOM_PROD = 'setNotFound',
  METHOD_SET_APPROVE_PRODUCT = 'setApproveProduct',
}
