import { Component } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import * as Sentry from '@sentry/angular';
import { tap } from 'rxjs/operators';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'app-crm-avto100';

  public get titleSpinner(): string {
    if (this.srv.showLoadInfo$.getValue().titleSpinner) {
      return this.srv.showLoadInfo$.getValue().titleSpinner;
    }
  }
  public get subtitleSpinner(): string {
    if (this.srv.showLoadInfo$.getValue().subtitle) {
      return this.srv.showLoadInfo$.getValue().subtitle;
    }
  }

  constructor(private auth: AuthService, public srv: SrvService) {
    this.auth
      .getUserPermission()
      .pipe(
        tap((val) => {
          Sentry.configureScope((scope) => {
            scope.setUser({
              id: String(val.workmanId),
            });
          });
        })
      )
      .subscribe((value) => this.srv.permission$.next(value));
  }
}
