import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../core/header.service';
import { Observable } from 'rxjs';
import { IMenuCrm, PeersInfo, TaskIndicator } from '../../interface';
import { AuthService } from '@core/services/auth.service';
import { InfoList, OrderCountList } from '@shared/main-layout/core/info-list';
import { MatDialog } from '@angular/material/dialog';
import { HeaderDopSaleComponent } from '../../dialog/header-dop-sale/header-dop-sale.component';
import { HeaderShieldInfoComponent } from '../../dialog/header-shield-info/header-shield-info.component';
import { HeaderInfoComponent } from '../../dialog/header-info/header-info.component';
import { Router } from '@angular/router';
import { HeaderSearchUserComponent } from '../../dialog/header-search-user/header-search-user.component';
import { HeaderTaskIndicatorListComponent } from '../../dialog/header-task-indicator-list/header-task-indicator-list.component';
import { SrvService } from 'repositories';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  loadMenu$: Observable<IMenuCrm[]> = this.hs.loadMenu();
  orderList: OrderCountList;
  taskIndicator: TaskIndicator;
  peersInfo: PeersInfo;

  constructor(
    private hs: HeaderService,
    private auth: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private srv: SrvService
  ) {}

  ngOnInit(): void {
    this.loadWorkInformationList();
  }

  loadWorkInformationList(): void {
    this.srv.information$.subscribe((value: InfoList) => {
      this.orderList = value.order;
      this.peersInfo = {
        countPeers: value.countPeers,
        countPeersBusy: value.countPeersBusy,
        peers: value.peers,
      };
      this.taskIndicator = {
        countTask: value.countTask,
        missedTaskList: value.missedTaskList,
        missedPresList: value.missedPresList,
        countPres: value.countPres,
        requestCount: value.requestCount,
        randomProduct: value.randomProduct,
        missedRandomProduct: value.missedRandomProduct,
      };
    });
  }

  setLogOut(): void {
    this.auth.logout();
  }

  showTaskIndicator(): void {
    this.dialog.open(HeaderTaskIndicatorListComponent, {
      maxHeight: '100vh',
      maxWidth: '100vw',
      minHeight: '100vh',
      minWidth: '100vw',
    });
  }

  createNewClient(): void {
    this.dialog.open(HeaderSearchUserComponent, {
      maxHeight: '100vh',
      maxWidth: '100vw',
      minHeight: '100vh',
      minWidth: '100vw',
    });
  }

  showShield($event: string): void {
    this.dialog.open(HeaderShieldInfoComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      minWidth: '55vw',
      data: {
        mark: $event,
      },
    });
  }

  showHelpInfo(): void {
    this.dialog.open(HeaderInfoComponent, {
      maxHeight: '100vh',
      maxWidth: '100vw',
      minHeight: '100vh',
      minWidth: '100vw',
      data: { url: this.router.url },
    });
  }
}
