import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-provider-count-table',
  templateUrl: './provider-count-table.component.html',
  styleUrls: ['./provider-count-table.component.scss'],
})
export class ProviderCountTableComponent implements OnInit {
  @Input() providerInfo;
  @Input() header = true;

  constructor() {}
  ngOnInit(): void {}
}
