import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IBrandFilter } from 'repositories';
import { FormControl, FormGroup } from '@angular/forms';
import { isEqual } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatCheckboxChange } from '@angular/material/checkbox';
@UntilDestroy()
@Component({
  selector: 'lib-expansion-checkbox',
  templateUrl: './expansion-checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionCheckboxComponent implements OnInit {
  public get filtersList(): IBrandFilter[] {
    return this._filtersList;
  }

  public set filtersList(filtersList: IBrandFilter[]) {
    if (isEqual(this._filtersList, filtersList)) {
      return;
    }
    this._filtersList = filtersList;
  }
  @Input()
  public set iFilterMainItem(iFilterMainItem: IBrandFilter[]) {
    if (this._iFilterMainItem === iFilterMainItem) {
      return;
    }
    this.filtersList = iFilterMainItem;

    this._iFilterMainItem = iFilterMainItem;
  }

  public get iFilterMainItem(): IBrandFilter[] {
    return this._iFilterMainItem;
  }

  @Input() control = '';

  @Input()
  public set filterForm(filterForm: FormGroup | null) {
    if (this._filterForm === filterForm) {
      return;
    }

    this._filterForm = filterForm;
  }

  public get filterForm(): FormGroup | null {
    return this._filterForm;
  }

  @Output()
  public readonly checkChange = new EventEmitter<{
    event: MatCheckboxChange;
    num: number | string;
    control: string;
  }>();

  public get startShowFilter(): number {
    return this._startShowFilter;
  }

  public set endShowFilter(endShowFilter: number) {
    if (this._endShowFilter === endShowFilter) {
      return;
    }

    this._endShowFilter = endShowFilter;
  }

  public get endShowFilter(): number {
    return this._endShowFilter;
  }

  filterInfo: FormControl = new FormControl();

  public defaultFilterLength = 5;

  private _iFilterMainItem: IBrandFilter[] = [];
  private _filtersList: IBrandFilter[] = [];

  private _filterForm: FormGroup | null = null;
  private _startShowFilter = 0;
  private _endShowFilter = 5;

  constructor() {}

  ngOnInit(): void {
    this.filters();
  }

  showMoreFilter(num: number): void {
    this.endShowFilter = num;
  }

  public checked(value: string, value2: string): boolean {
    return value ? value.includes(value2) : false;
  }

  onCheckChangeBrand(
    $event: MatCheckboxChange,
    num: number | string,
    control: string
  ): void {
    this.checkChange.emit({ event: $event, num, control });
  }

  filters(): void {
    this.filterInfo.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.filtersList = this.iFilterMainItem;

        if (value.length > 0) {
          this.filtersList = this.filtersList.filter((val) =>
            isEqual(
              val.name.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()),
              0
            )
          );
        }
      });
  }
}
