import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services/api.service';
import { OtherSpr, RequestBody } from '../../../../core/interfaces/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SrvService } from 'repositories';

@Injectable()
export class CpServiceService {
  constructor(private api: ApiService, private srv: SrvService) {}

  getSourceList(start, end): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprSourceRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(start),
        endDate: this.srv.convertDate(end),
      },
    };

    return this.api.post_jwt(q).pipe(map((data) => data));
  }

  getCarList(start, end): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprCarRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(start),
        endDate: this.srv.convertDate(end),
      },
    };
    return this.api.post_jwt(q).pipe(map((data) => data));
  }

  getFormList(start, end): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprFormSaleRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(start),
        endDate: this.srv.convertDate(end),
      },
    };
    return this.api.post_jwt(q).pipe(map((data) => data));
  }

  getClientList(params): Observable<any> {
    const q: RequestBody = {
      modelName: 'AnalyticsClient',
      calledMethod: 'getClientList',
      methodProperties: {
        type: 1,
        ...params,
      },
    };
    return this.api.post_jwt(q).pipe(map((data) => data));
  }
}
