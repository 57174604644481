import { Component, OnDestroy, OnInit } from '@angular/core';
import { SudService } from './sud.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { Sud } from './sud.interface';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DialogAddPaymentComponent } from './dialog-add-payment/dialog-add-payment.component';
import { DialogAddNewDeliveryApiComponent } from './dialog-add-new-delivery-api/dialog-add-new-delivery-api.component';
import { DialogLoadMoreOrderInfoComponent } from './dialog-load-more-order-info/dialog-load-more-order-info.component';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-struct-unit-dic',
  templateUrl: './struct-unit-dic.component.html',
  styles: [],
  providers: [SudService],
})
export class StructUnitDicComponent implements OnInit, OnDestroy {
  private destroy = new Subject<any>();

  form: FormGroup;
  showInfo: FormGroup;
  structUnit: FormControl;
  structUnitList$: Observable<Sud[]>;
  onLoadPayData$: Observable<any>;
  onLoadPayDataDelivery$: Observable<any>;

  constructor(
    private sud: SudService,
    public srv: SrvService,
    private dialog: MatDialog
  ) {
    this.structUnit = new FormControl();

    this.form = new FormGroup({
      name: new FormControl('', [Validators.minLength(5), Validators.required]),
      code: new FormControl(),
      email: new FormControl(),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
      ]),
    });

    this.showInfo = new FormGroup({
      start: new FormControl(new Date(), [Validators.required]),
      end: new FormControl(new Date(), [Validators.required]),
      returnProd: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.onLoadStrUnitList();
  }

  onLoadStrUnitList(): void {
    this.structUnitList$ = this.sud.onLoadStrUnitList();
  }

  onLoadPayData(): void {
    this.onLoadPayData$ = this.sud.onLoadPayData(
      this.srv.convertDate(this.showInfo.value.start),
      this.srv.convertDate(this.showInfo.value.end),
      this.structUnit.value.id,
      this.showInfo.value.returnProd
    );

    this.onLoadPayDataDelivery$ = this.sud.onLoadPayDataDelivery(
      this.srv.convertDate(this.showInfo.value.start),
      this.srv.convertDate(this.showInfo.value.end),
      this.structUnit.value.id,
      this.showInfo.value.returnProd
    );
  }

  // Универсальная функция обновления данных по выбранной СЕ
  refreshSUList(): void {
    this.onLoadStrUnitList();
    this.structUnitList$.subscribe((value) => {
      this.structUnit.setValue(
        value.filter((i) => +this.structUnit.value.id === +i.id)[0]
      );
    });
  }

  onCreateNewSU(): void {
    this.sud
      .onCreateNewSU()
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при создании СЕ. Обратитесь в техподдержку'
          );
          return of('Error in method onInit onCreateNewStrUnit ' + err);
        }),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.srv.successMessage('СЕ успешно создано');
        this.onLoadStrUnitList();
      });
  }

  onSetSU(): void {
    this.form.get('name').setValue(this.structUnit.value.name);
    this.form.get('phone').setValue(this.structUnit.value.phone);
    this.form.get('email').setValue(this.structUnit.value.email);
    this.form.get('code').setValue(this.structUnit.value.code);

    this.onLoadPayData();
  }

  // Работа с платежными данными по СЕ
  addNewPayment(): void {
    const dialog = this.dialog.open(DialogAddPaymentComponent, {
      data: {
        suId: this.structUnit.value.id,
      },
      maxHeight: '90vh',
      maxWidth: '90vh',
      minHeight: '20vh',
      minWidth: '50vh',
    });

    dialog.afterClosed().subscribe((val) => {
      if (+val.code === 200) {
        this.refreshSUList();
      }
    });
  }

  deletePayment(value: number): void {
    this.sud
      .deletePayment(value)
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при удалении реквизитов. Обратитесь в техподдержку'
          );
          return of('Error in method onInit deletePayment ' + err);
        }),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.srv.successMessage('Реквизиты удалены');
        this.refreshSUList();
      });
  }

  setDefaultPayment(id: any, type): void {
    this.sud
      .setDefaultPayment(id, type, this.structUnit.value.id)
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при изменении реквизитов. Обратитесь в техподдержку'
          );
          return of('Error in method onInit setDefaultPayment ' + err);
        }),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.srv.successMessage('Реквизиты установлены по умолчанию');
        this.refreshSUList();
      });
  }

  // Сохранение общих данных по СЕ
  saveDataSU(): void {
    this.sud
      .saveDataSU({
        phone: this.form.value.phone,
        name: this.form.value.name,
        code: this.form.value.code,
        email: this.form.value.email,
        suId: this.structUnit.value.id,
      })
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при сохранении данных. Обратитесь в техподдержку'
          );
          return of('Error in method onInit saveDataSU ' + err);
        }),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.srv.successMessage('Данные обновлены');
        this.refreshSUList();
      });
  }

  // Работа с АПИ реквизитами перевозчиков СЕ
  addNewDelivery(): void {
    const dialog = this.dialog.open(DialogAddNewDeliveryApiComponent, {
      data: {
        suId: this.structUnit.value.id,
      },
      maxHeight: '90vh',
      maxWidth: '90vh',
      minHeight: '20vh',
      minWidth: '50vh',
    });

    dialog.afterClosed().subscribe((val) => {
      if (+val.code === 200) {
        this.refreshSUList();
      }
    });
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }

  deleteDelivery(value: any): void {
    this.sud
      .deleteDelivery(value)
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при удалении АПИ Ключа. Обратитесь в техподдержку'
          );
          return of('Error in method onInit deleteDelivery ' + err);
        }),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.srv.successMessage('Реквизиты удалены');
        this.refreshSUList();
      });
  }

  setDefaultDelivery(id: any, devId: number): void {
    this.sud
      .setDefaultDelivery(id, devId, this.structUnit.value.id)
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при изменении перевозчика. Обратитесь в техподдержку'
          );
          return of('Error in method onInit setDefaultDelivery ' + err);
        }),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.srv.successMessage('Настройки перевозчика изменены');
        this.refreshSUList();
      });
  }

  showOrderInfo(filter: any, typeFilter: number): void {
    this.dialog.open(DialogLoadMoreOrderInfoComponent, {
      data: {
        filter,
        typeFilter,
        suId: this.structUnit.value.id,
        startDate: this.srv.convertDate(this.showInfo.value.start),
        endDate: this.srv.convertDate(this.showInfo.value.end),
      },
      maxHeight: '90vh',
      maxWidth: '90vh',
      minHeight: '20vh',
      minWidth: '50vh',
    });
  }
}
