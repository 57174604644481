import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatService } from '../core/chat.service';
import { takeUntil, tap } from 'rxjs/operators';
import { ProdInfo } from '../core/chat';
import { OtherSpr } from '@core/interfaces/interfaces';
import { SrvService } from 'repositories';

@Component({
  selector: 'lib-warehouse-chat-response',
  templateUrl: './warehouse-chat-response.component.html',
  providers: [ChatService],
})
export class WarehouseChatResponseComponent implements OnInit, OnDestroy {
  destroy = new ReplaySubject();
  warehouseList$: Observable<OtherSpr[]>;
  form: FormGroup;
  countList = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
  ];
  prodInfo$: Observable<ProdInfo>;
  prodInfoReq: ProdInfo;

  styleResponse: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private chat: ChatService,
    public srv: SrvService,
    private dialogRef: MatDialogRef<WarehouseChatResponseComponent>
  ) {
    this.form = new FormGroup({
      reqCat: new FormControl(+this.dialogData.category, [Validators.required]),
      warehouse: new FormControl(this.dialogData.warehouse),
      count: new FormControl(+this.dialogData.count),
      data: new FormControl(
        this.srv.convertDateTimeInput(this.dialogData.date)
      ),
      comment: new FormControl(this.dialogData.comment),
    });
    this.styleResponse = true;
  }

  ngOnInit(): void {
    this.loadWarehouse();
    this.loadProdInfo();
  }

  loadProdInfo(): void {
    this.prodInfo$ = this.chat
      .loadProdInfo(this.dialogData.id, this.dialogData.resNumber)
      .pipe(
        tap((val) => {
          this.prodInfoReq = val;
        })
      );
  }

  loadWarehouse(): void {
    this.warehouseList$ = this.chat.loadWarehouse(
      this.dialogData.id,
      this.dialogData.resNumber
    );
  }

  onCreateResponseRequest(): any {
    if (this.form.invalid) {
      this.srv.errorMessage('Не все поля заполнены');

      return false;
    }

    const obj = {
      typeRequest: 2,
      category: this.form.value.reqCat,
      title: this.dialogData.title,
      productId: this.dialogData.id,
      text_1: this.form.value.warehouse,
      text_2: this.form.value.count,
      resNumber: this.dialogData.resNumber,
      text_3: this.srv.convertDateTime(this.form.value.data),
      comment: this.form.value.comment,
    };

    this.chat
      .setResponseWarehouse(obj)
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        if (+value.code === 200) {
          this.dialogRef.close({ code: 200 });
          this.srv.successMessage('Ответ сохранен');
        }
      });
  }

  changeResponse(): void {
    if (+this.dialogData.warehouse !== +this.form.value.warehouse) {
      this.styleResponse = false;
    } else if (+this.dialogData.count !== +this.form.value.count) {
      this.styleResponse = false;
    } else if (
      this.dialogData.date !== this.srv.convertDateTime(this.form.value.data)
    ) {
      this.styleResponse = false;
    } else if (this.dialogData.comment !== this.form.value.comment) {
      this.styleResponse = false;
    } else {
      this.styleResponse = true;
    }
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }
}
