import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedTableComponent } from './shared-table.component';
import { TableModule } from 'primeng/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SharedTableComponent],
  imports: [CommonModule, TableModule, MatButtonModule, MatIconModule],
  exports: [SharedTableComponent],
})
export class SharedTableModule {}
