import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  PipeTransform,
  Pipe,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-selection-catalog',
  templateUrl: './selection-catalog.component.html',
  styleUrls: ['./selection-catalog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionCatalogComponent implements OnInit {
  constructor(private activeRouter: ActivatedRoute) {}
  modelLink;
  ngOnInit(): void {
    this.activeRouter.params.subscribe((val) => {
      switch (val.mark) {
        case 'AUDI':
          this.modelLink =
            'https://audi.catalogs-parts.com/#{client:1ep2hz;page:models;lang:ru;region:rdw}';

          break;
        case 'SEAT':
          this.modelLink =
            'https://seat.catalogs-parts.com/#{client:1ep2hz;page:models;lang:ru;region:e}';
          break;

        case 'VW':
          this.modelLink =
            'https://volkswagen.catalogs-parts.com/#{client:1ep2hz;page:models;lang:ru;region:rdw}';
          break;

        case 'SKODA':
          this.modelLink =
            'https://skoda.catalogs-parts.com/#{client:1ep2hz;page:models;lang:ru;region:cz}';
          break;
      }
    });
  }
}
