import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import {
  LastContact,
  SmallCallList,
  UserCard,
  UserDataOrder,
  UserPayment,
  UserRecipient,
} from '../core/ucl';
import { UclService } from '../core/ucl.service';
import { takeUntil, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DialogYesNoComponent } from '../../../../shared/dialog-yes-no/dialog-yes-no.component';
import { UcDialogAddNewPaymentComponent } from './uc-dialog-add-new-payment/uc-dialog-add-new-payment.component';
import { UcDialogEditPaymentComponent } from './uc-dialog-edit-payment/uc-dialog-edit-payment.component';
import { UcDialogAddNewRecipientComponent } from './uc-dialog-add-new-recipient/uc-dialog-add-new-recipient.component';
import { UcDialogEditRecipientComponent } from './uc-dialog-edit-recipient/uc-dialog-edit-recipient.component';
import { UcDialogAddNewCarComponent } from './uc-dialog-add-new-car/uc-dialog-add-new-car.component';
import { UcDialogEditCarComponent } from './uc-dialog-edit-car/uc-dialog-edit-car.component';
import { UcDialogEditUserInfoComponent } from './uc-dialog-edit-user-info/uc-dialog-edit-user-info.component';
import { OcService } from '../../../sale/order-card/core/oc.service';
import { Title } from '@angular/platform-browser';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
  providers: [UclService, OcService],
})
export class UserCardComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();

  userCardData$: Observable<UserCard>;
  userRecipientList$: Observable<UserRecipient[]>;
  userPaymentList$: Observable<UserPayment[]>;
  userCallList$: Observable<SmallCallList[]>;
  contactList$: Observable<LastContact[]>;
  userOrderData$: Observable<UserDataOrder>;
  hash: string;
  userData: UserCard;

  constructor(
    private activateRoute: ActivatedRoute,
    private ucl: UclService,
    public srv: SrvService,
    private oc: OcService,
    private dialog: MatDialog,
    private title: Title
  ) {
    this.hash = this.activateRoute.snapshot.params.hash;
  }

  ngOnInit(): void {
    // Не карточку если нет данных
    this.activateRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.hash = val.hash;

        this.loadingUserCardInfo();
        this.loadUserRecipient();
        this.loadUserPayment();
        this.loadCallList();
        this.loadLastContactList();
        this.loadUserOrderInfo();
      });
  }

  // Загрузить контактные данные
  loadingUserCardInfo(): void {
    this.userCardData$ = this.ucl.loadingUserCardInfo(this.hash).pipe(
      tap((val) => {
        this.userData = val;
        this.title.setTitle(
          'Карточка клиента ' +
            this.userData.lastName +
            ' ' +
            this.userData.name
        );
      })
    );
  }

  // загрузуить получателей
  loadUserRecipient(): void {
    this.userRecipientList$ = this.ucl.loadUserRecipient(this.hash);
  }

  // загрузить банковские карты
  loadUserPayment(): void {
    this.userPaymentList$ = this.ucl.loadUserPayment(this.hash);
  }

  // загрузить журнал звонков
  loadCallList(): void {
    this.userCallList$ = this.ucl.loadCallList(this.hash);
  }

  // загрузить последние контакты
  loadLastContactList(): void {
    this.contactList$ = this.ucl.loadLastContactList(this.hash);
  }

  // загрузииь заказы, авто и претензии
  loadUserOrderInfo(): void {
    this.userOrderData$ = this.ucl.loadUserOrderInfo(this.hash);
  }

  // копипаст
  trueCopy(): void {
    this.srv.successMessage('Скопировано');
  }

  // создать заказ с автомобилем
  onCreateNewOrderByCar(id): void {
    this.ucl
      .onCreateNewOrderByCar(this.hash, id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.loadUserOrderInfo();
        window.open('/crm/order-card/' + val.orderNumber, '_blank');
      });
  }

  // создать заказ с выбором источника
  createNewOrder(num: number): void {
    this.ucl
      .onCreateNewOrder(this.hash, num)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.loadUserOrderInfo();
        window.open('/crm/order-card/' + val.orderNumber, '_blank');
      });
  }

  // удалить автомобиль
  onDeleteCar(id): void {
    const dialog = this.dialog.open(DialogYesNoComponent, {
      data: {
        text:
          '<p>Вы собираетесь удалить автомобиль у клиента <b>' +
          this.userData.lastName +
          ' ' +
          this.userData.name +
          '</b></p>' +
          '<p>Удаленные данные восстановлению <b>не подлежат</b>.</p>' +
          '<h3 class="h3"><b>Вы уверены?</b></h3>',
        title: 'Удалить автомобиль',
        dismiss: 'Отмена',
        success: 'Удалить',
      },
    });

    dialog.afterClosed().subscribe((val) => {
      if (val.result) {
        this.ucl
          .onDeleteCar(this.hash, id)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.loadUserOrderInfo();
          });
      }
    });
  }

  // удалить банковскую карту
  onDeleteBankCard(id): void {
    const dialog = this.dialog.open(DialogYesNoComponent, {
      data: {
        text:
          '<p>Вы собираетесь удалить банковскую карту у клиента <b>' +
          this.userData.lastName +
          ' ' +
          this.userData.name +
          '</b></p>' +
          '<p>Удаленные данные восстановлению <b>не подлежат</b>.</p>' +
          '<h3 class="h3"><b>Вы уверены?</b></h3>',
        title: 'Удалить банковскую карту',
        dismiss: 'Отмена',
        success: 'Удалить',
      },
    });

    dialog.afterClosed().subscribe((val) => {
      if (val.result) {
        this.ucl
          .onDeleteBankCard(this.hash, id)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.srv.successMessage('Изменения сохранены');
            this.loadUserPayment();
          });
      }
    });
  }

  // удалить получателя
  onDeleteRecipient(id): void {
    const dialog = this.dialog.open(DialogYesNoComponent, {
      data: {
        text:
          '<p>Вы собираетесь удалить получателя у клиента <b>' +
          this.userData.lastName +
          ' ' +
          this.userData.name +
          '</b></p>' +
          '<p>Удаленные данные восстановлению <b>не подлежат</b>.</p>' +
          '<h3 class="h3"><b>Вы уверены?</b></h3>',
        title: 'Удалить получателя',
        dismiss: 'Отмена',
        success: 'Удалить',
      },
    });

    dialog.afterClosed().subscribe((val) => {
      if (val.result) {
        this.ucl
          .onDeleteRecipient(this.hash, id)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.srv.successMessage('Изменения сохранены');
            this.loadUserRecipient();
          });
      }
    });
  }

  // добавить банковскую карту
  onAddNewPayment(): void {
    const dialog = this.dialog.open(UcDialogAddNewPaymentComponent, {
      data: { hash: this.hash },
      maxHeight: '90vh',
    });

    dialog.afterClosed().subscribe((val) => {
      if (val.result) {
        this.loadUserPayment();
      }
    });
  }

  // редактировать банковскую карту
  onEditBankCard(id, num, name): void {
    const dialog = this.dialog.open(UcDialogEditPaymentComponent, {
      data: { hash: this.hash, id, num, name },
    });

    dialog.afterClosed().subscribe((val) => {
      if (val.result) {
        this.loadUserPayment();
      }
    });
  }

  // добавить нового получателя
  onAddNewRecipient(): void {
    const dialog = this.dialog.open(UcDialogAddNewRecipientComponent, {
      data: { hash: this.hash },
      maxHeight: '90vh',
    });

    dialog.afterClosed().subscribe((val) => {
      if (val.result) {
        this.loadUserRecipient();
      }
    });
  }

  // редактировать банковскую карту
  onEditRecipient(id, name, lastName, middleName, phone): void {
    const dialog = this.dialog.open(UcDialogEditRecipientComponent, {
      data: { hash: this.hash, id, name, lastName, middleName, phone },
    });

    dialog.afterClosed().subscribe((val) => {
      if (val.result) {
        this.loadUserRecipient();
      }
    });
  }

  // добавить новый автомобиль клиенту
  addNewUserCar(): void {
    const dialog = this.dialog.open(UcDialogAddNewCarComponent, {
      data: { hash: this.hash },
      maxHeight: '90vh',
    });

    dialog.afterClosed().subscribe((val) => {
      if (+val.result === 200) {
        this.loadUserOrderInfo();
      }
    });
  }

  // изменить автомобиль клиента
  onEditCar(id): void {
    const dialog = this.dialog.open(UcDialogEditCarComponent, {
      data: { hash: this.hash, id },
    });

    dialog.afterClosed().subscribe((val) => {
      if (+val.result === 200) {
        this.loadUserOrderInfo();
      }
    });
  }

  // изменить данные клиента
  editUserInfo(): void {
    const dialog = this.dialog.open(UcDialogEditUserInfoComponent, {
      data: {
        hash: this.hash,
        name: this.userData.name,
        lastName: this.userData.lastName,
        middleName: this.userData.middleName,
        phone: this.userData.phoneList ? this.userData.phoneList[0].text : '',
        viber: this.userData.phoneList ? this.userData.phoneList[0].viber : '',
        phoneDop: this.userData.phoneList[1]
          ? this.userData.phoneList[1].text
          : '',
        viberDop: this.userData.phoneList[1]
          ? this.userData.phoneList[1].viber
          : '',
        email: this.userData.emailList[0].text,
        type: this.userData.typeId.toString(),
        badType: this.userData.badTypeId,
        cashBackRate: this.userData.cashBackRate,
        action: this.userData.actionId,
        sex: this.userData.sex,
        manager: this.userData.managerId,
        unit: this.userData.unitId,
      },
    });

    dialog.afterClosed().subscribe((val) => {
      if (+val.result === 200) {
        this.loadingUserCardInfo();
      }
    });
  }

  // Позвонить клиенту
  callClient(phone): void {
    this.oc
      .setCall(phone)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.srv.primaryMessage('Идет исходяший звонок');
      });
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createPersonalCabinet(): void {
    this.ucl
      .createPersonalCabinet(this.hash)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.loadingUserCardInfo();
        this.srv.successMessage('Кабинет создан');
      });
  }
}
