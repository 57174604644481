import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UclService } from '../../core/ucl.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-uc-dialog-edit-payment',
  templateUrl: './uc-dialog-edit-payment.component.html',
  styleUrls: ['./uc-dialog-edit-payment.component.scss'],
  providers: [UclService],
})
export class UcDialogEditPaymentComponent implements OnInit {
  formPayment: FormGroup;

  constructor(
    private ucl: UclService,
    private srv: SrvService,
    private dialogRef: MatDialogRef<UcDialogEditPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.formPayment = new FormGroup({
      number: new FormControl(this.dialogData.num, [
        Validators.required,
        Validators.minLength(16),
      ]),
      name: new FormControl(this.dialogData.name),
    });
  }

  ngOnInit(): void {}

  addNewPayment(): any {
    if (this.formPayment.invalid) {
      this.srv.errorMessage('Форма заполнена не верно');
      return false;
    }

    const obj = {
      id: this.dialogData.id,
      hash: this.dialogData.hash,
      bankCard: this.formPayment.value.number,
      bankCardUser: this.formPayment.value.name,
    };

    this.ucl.editPayment(obj).subscribe(() => {
      this.dialogRef.close({ result: true });
      this.srv.successMessage('Изменения сохранены');
    });
  }
}
