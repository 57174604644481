import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RequestBody } from '../interfaces/interfaces';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(private http: HttpClient) {}

  private static getParams(
    modelName: string,
    calledMethod: string,
    methodProperties: { [key: string]: any }
  ): HttpParams {
    return new HttpParams()
      .set('modelName', modelName)
      .set('calledMethod', calledMethod)
      .set('methodProperties', JSON.stringify(methodProperties));
  }
  post(data: RequestBody): Observable<any> {
    return this.http.post(environment.url, data);
  }

  post_jwt(data: RequestBody): Observable<any> {
    return this.http.post(environment.url_jwt, data);
  }
  post_jwt_text(data: RequestBody): Observable<any> {
    return this.http.post(environment.url_jwt, data, { responseType: 'text' });
  }

  postEpUploadFile(data: FormData): Observable<any> {
    return this.http.post(environment.url_jwt_file, data);
  }

  postEpUploadFileProvider(data: FormData): Observable<any> {
    return this.http.post(environment.api_jwt_file_provider, data);
  }

  postDeleteFidFile(data: FormData): Observable<any> {
    return this.http.post(environment.api_jwt_file_fid_delete, data);
  }

  postCreateFidFileProductList(data: FormData): Observable<any> {
    return this.http.post(environment.api_jwt_file_fid_create, data);
  }

  uploadFileDisapproved(data: FormData): Observable<any> {
    return this.http.post(environment.api_jwt_file_fid_disapproved, data);
  }

  uploadFileLabel2(data: FormData): Observable<any> {
    return this.http.post(environment.api_jwt_file_fid_label2, data);
  }

  postEpUploadFileImgBrand(data: FormData): Observable<any> {
    return this.http.post(environment.api_jwt_file_img_brand, data);
  }

  postEpUploadFileImgProd(data: FormData): Observable<any> {
    return this.http.post(environment.api_jwt_file_img_prod, data);
  }

  postEpUploadFileRequestAds(data: FormData): Observable<any> {
    return this.http.post(environment.api_jwt_file_request_ads, data);
  }

  postEpUploadFilePrice(data: FormData): Observable<any> {
    return this.http.post(environment.api_jwt_file_price, data);
  }
  uploadFileProductCard(data: FormData): Observable<any> {
    return this.http.post(environment.api_jwt_file_product_card, data);
  }

  uploadFileProductCardPhoto(data): Observable<any> {
    return this.http.post(environment.api_jwt_file_product_card, data);
  }

  getSite({
    modelName,
    calledMethod,
    methodProperties,
  }: RequestBody): Observable<any> {
    const params = ApiService.getParams(
      modelName,
      calledMethod,
      methodProperties
    );

    return this.http.get(environment.urlGetSite, { params });
  }
}
