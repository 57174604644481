export enum SummaryHeader {
  inCall = 'Вхідні дзвінки',
  outCall = 'Вихідні дзвінки',
  manager = 'Менеджер',
  countOrder = 'Замовлення',
  countNewOrder = 'Нові замовлення',
  countProduct = 'Товарів (ун)',
  countProductAlertInfo = 'Наповнення товару',
  countOrderInWork = 'Замовлення в роботі',
  operationalCountTask = 'Операційні задачі',
  operationalCountTaskAlert = 'Операційні задачі (прострочені)',
  projectCountTask = 'Проєктні задачі',
  projectCountTaskAlert = 'Проєктні задачі (прострочені)',
  adminCountTask = 'Адміністративні задачі',
  adminCountTaskAlert = 'Адміністративні задачі (прострочені)',
  pretensionCountTask = 'Претензії',
  pretensionCountTaskAlert = 'Претензії (прострочені)',
  countRequest = 'Запити',
  countRequestFact = 'Факт за сегодня',
  countRequestAlert = 'Запити (прострочені)',
}
