import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  ApiRequest,
  BarcodePrefix,
  ContactInfo,
  SrvService,
} from 'repositories';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '@core/services/api.service';

@UntilDestroy()
@Component({
  selector: 'lib-barcode-scanner',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarcodeScannerComponent implements OnInit {
  private inputBuffer = '';
  private timeout: any;
  private lastInputTime = 0;

  constructor(
    private readonly srv: SrvService,
    private readonly router: Router,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.listenToKeyboard();
    this.barcodeHandler();
  }

  private listenToKeyboard(): void {
    window.addEventListener('keypress', (event: KeyboardEvent) => {
      const currentTime = new Date().getTime();

      if (currentTime - this.lastInputTime > 500) {
        this.inputBuffer = '';
      }

      this.lastInputTime = currentTime;

      if (event.key === 'Enter') {
        if (this.inputBuffer.length > 0) {
          this.handleBarcode(this.inputBuffer); // Проверка и разделение штрихкодов
        }
        this.inputBuffer = '';
        event.preventDefault(); // Отключаем стандартное действие
        return; // Выходим из обработчика
      } else {
        this.inputBuffer += event.key;
      }

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.inputBuffer = '';
      }, 500);
    });
  }

  private handleBarcode(barcode: string): void {
    console.log('test barcode', barcode);
    this.srv.barcodeData$.next(barcode);
  }

  private barcodeHandler(): void {
    this.srv.barcodeData$.pipe(untilDestroyed(this)).subscribe((barcode) => {
      const [prefix, value] = barcode.split('-');

      switch (+prefix) {
        case BarcodePrefix.HandleProduct:
          this.router.navigate(['crm', 'payment-product'], {
            queryParams: { acceptance: true, providerCode: value },
          });
          break;
        case BarcodePrefix.Order:
          this.postRequest(
            ApiRequest.orderClass.name,
            ApiRequest.orderClass.method.loadContactData,
            {
              orderNumber: value,
            }
          )
            .pipe(untilDestroyed(this))
            .subscribe((val: ContactInfo) => {
              switch (+val.actionId) {
                case 10:
                  this.router.navigate(['crm', 'packing']).then(() => {
                    this.srv.barcodeProductData$.next(value);
                  });
                  break;
                default:
                  this.router.navigate(['crm', 'order-card', value]).then();
                  break;
              }
            });

          break;
        default:
          this.srv.barcodeProductData$.next(barcode);
          break;
      }
    });
  }

  postRequest(className: string, method: string, value?: any): Observable<any> {
    return this.api.post_jwt({
      modelName: className,
      calledMethod: method,
      methodProperties: {
        ...value,
      },
    });
  }
}
