import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { HeaderService } from '../../core/header.service';
import {
  IActionState,
  IDopSaleProduct,
  IResultData,
  SrvService,
} from 'repositories';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { IFastFilter } from '../../interface';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'lib-header-dop-sale-list',
  templateUrl: './header-dop-sale-list.component.html',
})
export class HeaderDopSaleListComponent implements OnInit {
  title: string;

  public form: FormGroup;

  public actionState: IActionState = {
    edit: false,
    delete: false,
    on: false,
    off: false,
    link: true,
  };
  public readonly searchFieldList: string[] = [
    'katalNumber',
    'brand',
    'nomen',
    'cri',
    'saleCost',
  ];

  public criControl = 'cri';
  public brandControl = 'brand';
  public productList: IResultData<IDopSaleProduct>;
  public productListBase: IResultData<IDopSaleProduct>;
  loadProdList$: Observable<IFastFilter<IDopSaleProduct>> = this.hs
    .loadFastProdList({
      id: this.dialogData.id,
      clientCri: this.dialogData.clientCriId,
    })
    .pipe(
      tap((res) => {
        this.productList = { cols: res.cols, rows: res.rows };
        this.productListBase = cloneDeep({ cols: res.cols, rows: res.rows });
        this.srv.showLoadInfo$.next({ status: false });
      })
    );

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { id: number; name: string; clientCriId: number },
    private hs: HeaderService,
    private srv: SrvService,
    private _fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.title = this.dialogData.name;
    this.srv.showLoadInfo$.next({
      status: true,
      titleSpinner: 'Ожидайте идет загрузка',
    });
    this.initForm();
    this.subscribeToFormChange();
  }

  private initForm(): void {
    this.form = this._fb.group({
      brand: this._fb.array([]),
      cri: this._fb.array([]),
    });
  }

  private subscribeToFormChange(): void {
    this.form.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((form) => {
        this.productList.rows = cloneDeep(this.productListBase.rows);

        if (form.brand.length > 0) {
          const productList = [];

          this.productList.rows.forEach((item) => {
            if (form.brand.includes(item.brand.toLocaleUpperCase())) {
              productList.push(item);
            }
          });
          this.productList.rows = cloneDeep(productList);
        }

        if (form.cri.length > 0) {
          const productList = [];

          this.productList.rows.forEach((item) => {
            const criId = item.criIdList ? item.criIdList.split(',') : [];
            if (criId.length > 0) {
              const criLenght = form.cri.length;
              let i = 0;
              criId.forEach((val) => {
                form.cri.forEach((valItem) => {
                  if (+valItem === +val) {
                    i++;
                  }
                });
              });
              if (i === criLenght) {
                productList.push(item);
              }
            }
          });

          this.productList.rows = cloneDeep(productList);
        }
      });
  }

  onCheckChangeBrand(
    event: any,
    value: string | number,
    control: string
  ): void {
    const formArray: FormArray = this.form.get(control) as FormArray;
    /* Selected */
    if (event.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(value));
    } else {
      /* unselected */
      // find the unselected element
      let i = 0;

      // @ts-ignore
      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value === value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }

    const arrayBand: string[] = [];
    const arrayCri: number[] = [];
    this.form.value.brand.forEach((valueBrand: string) => {
      if (String(valueBrand) !== 'NaN') {
        arrayBand.push(valueBrand);
      }
    });
    this.form.value.brand = arrayBand;

    this.form.value.cri.forEach((valueCri: number) => {
      if (String(valueCri) !== 'NaN' && Number(valueCri) !== 0) {
        arrayCri.push(valueCri);
      }
    });
    this.form.value.cri = arrayCri;
  }

  private contains(where, what): boolean {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < what.length; i++) {
      if (where.indexOf(+what[i]) === -1) return false;
    }
    return true;
  }
}
