import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../../core/interfaces/interfaces';
import { AuthService } from '../../core/services/auth.service';
import { Router } from '@angular/router';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  hide = true;
  submitted = false;

  constructor(
    public auth: AuthService,
    private router: Router,
    private srv: SrvService
  ) {}

  ngOnInit(): any {
    this.form = new FormGroup({
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
    });
  }

  login(): any {
    if (this.form.invalid) {
      return;
    }
    this.submitted = true;
    const user: User = {
      phone: this.form.value.phone,
      password: this.form.value.password,
    };

    this.auth.login(user).subscribe(
      () => {
        this.form.reset();
        this.submitted = false;
        this.router.navigate(['/crm']);
        this.srv.startSSE$.next(true);
      },
      () => {
        this.submitted = false;
      }
    );
  }
}
