import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OtherSpr, RequestBody } from '@core/interfaces/interfaces';
import { ApiService } from '@core/services/api.service';
import {
  DashboardPack,
  DashboardPlanPack,
  DashboardSend,
  PackingOrder,
  PackingOrderLowInfo,
} from './pi';
import { SendOrderInfo } from '../../send/core/interface/get-send-ttnlist';

@Injectable()
export class PsService {
  constructor(private api: ApiService) {}

  getPackingOrderList(
    typeLoad?,
    delivery?,
    setStep?,
    checkProd?,
    date?
  ): Observable<PackingOrder[]> {
    const q: RequestBody = {
      modelName: 'Packing',
      // calledMethod: 'getPackingOrderList',
      calledMethod: 'getPackingOrderListGrp',

      methodProperties: {
        type: 1,
        typeLoad,
        delivery,
        setStep,
        checkProd,
        date,
      },
    };
    return this.api.post_jwt(q);
  }

  getOrderInfoToLowPack(orderNumber): Observable<PackingOrderLowInfo> {
    const q: RequestBody = {
      modelName: 'Packing',
      calledMethod: 'getOrderInfoToLowPack',
      methodProperties: {
        type: 1,
        orderNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  setPackOrder(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Packing',
      calledMethod: 'setPackOrder',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  getAllPackOrder(phone, orderNumber, planDateSend): Observable<any> {
    const q: RequestBody = {
      modelName: 'Packing',
      calledMethod: 'getAllPackOrder',
      methodProperties: {
        type: 1,
        phone,
        orderNumber,
        planDateSend,
      },
    };
    return this.api.post_jwt(q);
  }

  onLoadTtnInfo(orderList): Observable<any> {
    const q: RequestBody = {
      modelName: 'Packing',
      calledMethod: 'onLoadTtnInfo',
      methodProperties: {
        type: 1,
        orderList,
      },
    };
    return this.api.post_jwt(q);
  }

  setCronSendOrder(): Observable<any> {
    const q: RequestBody = {
      modelName: 'Packing',
      calledMethod: 'setCronSendOrder',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }
  onUpdatePackProductTtn(list): Observable<any> {
    const q: RequestBody = {
      modelName: 'Packing',
      calledMethod: 'onUpdatePackProductTtn',
      methodProperties: {
        type: 1,
        list,
      },
    };
    return this.api.post_jwt(q);
  }
  updateScanProductInfo(list): Observable<any> {
    const q: RequestBody = {
      modelName: 'Packing',
      calledMethod: 'updateScanProductInfo',
      methodProperties: {
        type: 1,
        list,
      },
    };
    return this.api.post_jwt(q);
  }

  onCreateTtn(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Packing',
      calledMethod: 'onCreateTtn',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  onSendOrderFromTtn(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Packing',
      calledMethod: 'onSendOrderFromTtn',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  onSaveTtn(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Packing',
      calledMethod: 'onSaveTtn',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  onPrintTtn(ttn): Observable<any> {
    const q: RequestBody = {
      modelName: 'Packing',
      calledMethod: 'onPrintTtn',
      methodProperties: {
        type: 1,
        ttn,
      },
    };
    return this.api.post_jwt(q);
  }

  loadDashboard(val): Observable<DashboardPack> {
    const q: RequestBody = {
      modelName: 'Packing',
      calledMethod: 'loadDashboard',
      methodProperties: {
        type: 1,
        ...val,
      },
    };
    return this.api.post_jwt(q);
  }

  loadSendDashboard(allOrder): Observable<DashboardSend[]> {
    const q: RequestBody = {
      modelName: 'SendOrder',
      calledMethod: 'loadSendDashboard',
      methodProperties: {
        type: 1,
        allOrder,
      },
    };
    return this.api.post_jwt(q);
  }

  loadSendOrderLists(
    delivery: string,
    action?: string,
    allOrder?
  ): Observable<any> {
    const q: RequestBody = {
      modelName: 'SendOrder',
      calledMethod: 'loadSendOrderLists',
      methodProperties: {
        type: 1,
        delivery,
        action,
        allOrder,
      },
    };
    return this.api.post_jwt(q);
  }

  getLoadSendInfo(orderList: any): Observable<SendOrderInfo[]> {
    const q: RequestBody = {
      modelName: 'SendOrder',
      calledMethod: 'getLoadSendInfo',
      methodProperties: {
        type: 1,
        orderList,
      },
    };
    return this.api.post_jwt(q);
  }

  setPaymentOrder(orderPayList: any[]): Observable<{ code: number }> {
    const q: RequestBody = {
      modelName: 'Bookkeeping',
      calledMethod: 'setPaymentOrderToCashBox',
      methodProperties: {
        type: 1,
        cashBoxId: 1,
        orderPayList,
      },
    };
    return this.api.post_jwt(q);
  }

  loadPackDashboard(): Observable<DashboardPlanPack> {
    const q: RequestBody = {
      modelName: 'Packing',
      calledMethod: 'loadPackDashboard',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }

  loadOeInfo(): Observable<any> {
    const q: RequestBody = {
      modelName: 'Server',
      calledMethod: 'loadOeInfoOrder',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }

  deleteTTN(orderList): Observable<any> {
    const q: RequestBody = {
      modelName: 'Packing',
      calledMethod: 'deleteTTN',
      methodProperties: {
        type: 1,
        orderList,
      },
    };
    return this.api.post_jwt(q);
  }

  loadUnitList(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      methodProperties: {},
      modelName: 'StructureUnit',
      calledMethod: 'loadUnitList',
    };
    return this.api.post_jwt(q);
  }
}
