export const ApiRequest = {
  productPaymentClass: {
    name: 'PaymentProduct',
    method: {
      getProductBarCodeInfo: 'getProductBarCodeInfo',
      loadProductForAcceptanceList: 'loadProductForAcceptanceList',
      loadAllProviderList: 'loadAllProviderList',
      loadSearchProduct: 'loadSearchProduct',
      loadDashBoardPaymentOrder: 'loadDashBoardPaymentOrder',
      loadProductList: 'loadProductList',
      loadProductListArchive: 'loadProductListArchive',
      loadActionProductList: 'loadActionProductList',
      loadProviderList: 'loadProviderList',
      loadUnitList: 'loadUnitList',
      loadTimeDeliveryProductList: 'loadTimeDeliveryProductList',
      loadDeliveryTypeList: 'loadDeliveryTypeList',
      loadTaskReturn: 'loadTaskReturn',
      addNewProductToPrice: 'addNewProductToPrice',
      loadProductListToFastReturn: 'loadProductListToFastReturn',
      setReturnProduct: 'setReturnProduct',
      onSetChangeProvider: 'onSetChangeProvider',
      onSetReservedToProvider: 'onSetReservedToProvider',
      onSetStatusToPay: 'onSetStatusToPay',
      loadProductInfo: 'loadProductInfo',
      getProductInfoAboutZakazNumber: 'getProductInfoAboutZakazNumber',
      setAdminProductChange: 'setAdminProductChange',
      getNewSiteCost: 'getNewSiteCost',
      returnProductPayment: 'returnProductPayment',
      returnProductNotPay: 'returnProductNotPay',
      returnProductOnDelivery: 'returnProductOnDelivery',
      returnProductAfterDelivery: 'returnProductAfterDelivery',
      addProductToOrder: 'onAddProductToOrder',
      readMessageComment: 'readMessageComment',
      sendNewComment: 'sendNewComment',
      onUpdateProduct: 'onUpdateProduct',
      loadProductComment: 'loadProductComment',
      getTimeList: 'getTimeList',
      submitPaymentProduct: 'submitPaymentProduct',
      fastUpdateProd: 'fastUpdateProd',
      submitHaveProduct: 'submitHaveProduct',
      setFastReturnProduct: 'setFastReturnProduct',
      addCommentToProduct: 'addCommentToProduct',
      loadProductSendAfterToday: 'loadProductSendAfterToday',
      setLogPaymentData: 'setLogPaymentData',
      loadLogInfo: 'loadLogInfo',
      loadContractInfo: 'loadContractInfo',
    },
  },
  warehouseClass: {
    name: 'Warehouse',
    method: {
      deleteReservedProduct: 'deleteReservedProduct',
      getDeliveryCost: 'getDeliveryCost',
    },
  },
  productCardLibraryClass: {
    name: 'ProductCardLibrary',
    method: {
      METHOD_LOAD_MARK_LIST: 'loadMarkList',
      METHOD_LOAD_MARK_PRODUCT: 'loadMarkProduct',
      METHOD_LOAD_MARK_INFO: 'loadMarkInfo',
      METHOD_LOAD_MODEL_INFO: 'loadModelInfo',
      METHOD_SAVE_MARK_INFO: 'saveMarkInfo',
      METHOD_SAVE_MODEL_INFO: 'saveModelInfo',
      METHOD_LOAD_TREE_LIST: 'loadTreeList',
      METHOD_LOAD_PHOTO_TO_BRAND: 'loadPhotoToBrand',
      METHOD_LOAD_PHOTO_LIST: 'loadPhotoList',
      METHOD_LOAD_PHOTO_LIST_ACTION: 'loadPhotoListAction',
      METHOD_LOAD_BRAND_SUMMARY: 'loadBrandSummary',
      METHOD_RETURN_PHOTO: 'returnPhoto',
      METHOD_LOAD_IMAGE_BASE_64: 'loadImageBase64',
      METHOD_SET_WORK_PHOTO: 'setWorkPhoto',
      METHOD_SET_DELETE_PHOTO: 'setDeletePhoto',
      METHOD_SET_MANAGER_PHOTO: 'setManagerPhoto',
      METHOD_SET_LOAD_BRAND_PRODUCT: 'loadBrandProduct',
      METHOD_SET_LOAD_PTP_LIST: 'loadPTPList',
      METHOD_SET_ONE_PRIORITY: 'setOnePriority',
      METHOD_SET_TWO_PRIORITY: 'setTwoPriority',
      METHOD_SET_ACTIVATE_PHOTO: 'setActivatePhoto',
      METHOD_LOAD_FAVORITE_PROD: 'loadFavoriteProd',
      METHOD_DELETE_FAVORITE_PROD: 'deleteFavoriteProd',
      METHOD_CLOSE_PRODUCT_IN_CARD: 'closeProductInCard',
      METHOD_ADD_PRODUCT_IN_CARD: 'addProductInCard',
      METHOD_LOAD_FAVORITE_TREE: 'loadFavoriteTree',
      METHOD_LOAD_CRI_LIST: 'loadCriList',
      METHOD_LOAD_CRI_INFO: 'loadCriInfo',
      METHOD_ADD_VALUE_TO_CRI: 'addNewValueToCri',
      METHOD_ADD_CRI: 'addNewCri',
      METHOD_ADD_PRODUCT_SALE_PRODUCT: 'addProductSaleProduct',
      METHOD_DELETE_PRODUCT_SALE_PRODUCT: 'deleteProductSaleProduct',
      METHOD_LOAD_MANAGER_PHOTO_LIST: 'loadManagerPhotoList',
      METHOD_LOAD_ALTERNATIVE_PRODUCT: 'loadAlternativeProd',
      loadLogType: 'loadLogType',
      loadLogManager: 'loadLogManager',
      loadLogInfo: 'loadLogInfo',
      METHOD_SET_TO_WORK: 'setToWork',
      METHOD_SAVE_ALTERNATIVE_PRODUCT: 'saveAlternativeProd',
      METHOD_DELETE_ALTERNATIVE_PRODUCT: 'deleteAlternativeProd',
      METHOD_LOAD_PROVIDER_LIST: 'loadProviderList',
      METHOD_LOAD_PROVIDER_BRAND_TREE_INFO: 'loadProviderBrandTreeInfo',
      METHOD_LOAD_PROVIDER_TREE_BRAND_INFO: 'loadProviderTreeBrandInfo',
      METHOD_LOAD_PROVIDER_PRODUCT_LIST: 'loadProviderProductList',
      METHOD_LOAD_PROVIDER_MARK_LIST: 'loadProviderMarkList',
      METHOD_LOAD_PROVIDER_Model_LIST: 'loadProviderModelList',
      METHOD_SET_TREE_TO_PROD: 'setTreeToProd',
      METHOD_SET_CAR_TO_PROD: 'setCarToProd',
      METHOD_SET_NOMEN_TO_PROD: 'setNomenToProd',
      METHOD_SET_REPLACE_NOMEN_TO_PROD: 'setReplaceNomenToProd',
      METHOD_LOAD_PROVIDER_BRAND_INFO: 'loadProviderBrandInfo',
      METHOD_LOAD_PROVIDER_TREE_INFO: 'loadProviderTreeInfo',
      METHOD_LOAD_PROVIDER_MARK_INFO: 'loadProviderMarkInfo',
      METHOD_LOAD_PROVIDER_MODEL_INFO: 'loadProviderModel',
      METHOD_LOAD_STAT_INFO_PROVIDER: 'loadStatInfoProvider',
      dopSaleLoadTree: 'dopSaleLoadTree',
      loadDopSaleProductList: 'loadDopSaleProductList',
      treeList: 'treeList',
      loadProductList: 'loadProductList',
      clientCriList: 'clientCriList',
      addDopSaleProduct: 'addDopSaleProduct',
      deleteDopSaleProduct: 'deleteDopSaleProduct',
    },
  },
  orderClass: {
    name: 'OrderCard',
    method: {
      loadContactData: 'loadContactData',
      loadCommentList: 'loadCommentList',
      saveOrderComment: 'saveOrderComment',
      loadCarList: 'loadCarList',
      loadMarkList: 'loadMarkList',
      loadModelList: 'loadModelList',
      changeOrderCar: 'changeOrderCar',
      loadDeliveryData: 'loadDeliveryData',
      loadDeliveryList: 'loadDeliveryList',
      loadPaymentList: 'loadPaymentList',
      loadRecipientList: 'loadRecipientList',
      saveOrderCity: 'saveOrderCity',
      setDeliveryType: 'setDeliveryType',
      saveWarehouseCity: 'saveWarehouseCity',
      saveDeliveryInfo: 'saveDeliveryInfo',
      setRecipientOrder: 'setRecipientOrder',
      setPaymentType: 'setPaymentType',
      setWaitPayment: 'setWaitPayment',
      setPlanDateSend: 'setPlanDateSend',
      setPlanDateSendWork: 'setPlanDateSendWork',
      saveSendComment: 'saveSendComment',
      loadProductData: 'loadProductData',
      deleteProdInOrder: 'deleteProdInOrder',
      addRandomProduct: 'addRandomProduct',
      loadProductInfo: 'loadProductInfo',
      loadProductComment: 'loadProductComment',
      getSaleProduct: 'getSaleProduct',
      searchOENum: 'searchOENum',
      readMessage: 'readMessage',
      sendNewComment: 'sendNewComment',
      saveCardProduct: 'saveCardProduct',
      loadOrderBtnData: 'loadOrderBtnData',
      loadSprSelectionWhi: 'loadSprSelectionWhi',
      loadSprGroup: 'loadSprGroup',
      getSelectionCondition: 'getSelectionCondition',
      onSetSelection: 'onSetSelection',
      loadSprWhiCancel: 'loadSprWhiCancel',
      subReasonList: 'subReasonList',
      loadOrderList: 'loadOrderList',
      onSetCancel: 'onSetCancel',
      setSaleRate: 'setSaleRate',
      loadHistoryList: 'loadHistoryList',
      loadCheckList: 'loadCheckList',
      setRefreshCheckSms: 'setRefreshCheckSms',
      copyOrder: 'copyOrder',
      setToWork: 'setToWork',
      setToWorkSpec: 'setToWorkSpec',
      loadSendInfo: 'loadSendInfo',
      loadSendListInfo: 'loadSendListInfo',
      loadBankClientCard: 'loadBankClientCard',
      sendReturnTtn: 'sendReturnTtn',
      saveNewTTN: 'saveNewTTN',
      editFormDelivery: 'editFormDelivery',
      loadTotalTask: 'loadTotalTask',
      loadTotalPretension: 'loadTotalPretension',
      getConfirmCondition: 'getConfirmCondition',
      loadCheckProductOnCar: 'loadCheckProductOnCar',
      loadConfirmInfo: 'loadConfirmInfo',
      onSetConfirm: 'onSetConfirm',
      loadDopSaleTree: 'loadDopSaleTree',
      setClientCri: 'setClientCri',
      loadDopSaleProductList: 'loadDopSaleProductList',
      addDopSaleProductToOrder: 'addDopSaleProductToOrder',
    },
  },
  voipClass: {
    name: 'VoIp',
    method: {
      setCall: 'setCall',
      updateCallInfo: 'updateCallInfo',
    },
  },
  balanceProviderClass: {
    name: 'BalanceProvider',
    method: {
      loadContractInfo: 'loadContractInfo',
    },
  },
  smsClass: {
    name: 'Sms',
    method: {
      sendRandomSms: 'sendRandomSms',
    },
  },
  deliveryClass: {
    name: 'Delivery',
    method: {
      getWarehouseList: 'getWarehouseList',
      getCityList: 'getCityList',
    },
  },
  taskClass: {
    name: 'Task',
    method: {
      createResponseViber: 'createResponseViber',
      createCallBackToLatter: 'onCreateNewTaskCallBackToLatter',
      onCreateNewTaskCallBack: 'onCreateNewTaskCallBack',
      createNewTask: 'onCreateNewTask',
      loadTypeTaskList: 'loadTypeTaskList',
      loadManagerList: 'loadManagerList',
      loadProductList: 'getProductToPretension',
      createPretension: 'onCreatePretension',
      reasonList: 'getReasonList',
      getReturnReason: 'getReturnReason',
    },
  },
  clientClass: {
    name: 'Client',
    method: {
      loadCarList: 'loadCarList',
      onDeleteCar: 'onDeleteClientCars',
      addNewCarWithOutCar: 'addCarWithMark',
      addNewCar: 'addNewCar',
      loadCarInfo: 'loadCarInfo',
      editCar: 'editCar',
      loadRecipientAllList: 'loadRecipientAllList',
      deleteRecipient: 'onDeleteRecipient',
      onEditRecipient: 'onEditRecipient',
      addNewRecipient: 'addNewRecipient',
    },
  },
  serverClass: {
    name: 'Server',
    method: {
      loadActiveUnit: 'loadActiveUnit',
      loadAlertInfoText: 'loadAlertInfoText',
      saveAlertInfo: 'saveAlertInfo',
    },
  },
  returnClass: {
    name: 'ReturnProduct',
    method: {
      getFinalResults: 'getFinalResults',
      getReturnRegister: 'getReturnRegisterNew',
      returnProductForOrder: 'returnProductForOrder',
      updateStatusReturn: 'updateStatusReturn',
      getStockManCmt: 'getStockManCmt',
      addStockManCmt: 'addStockManCmt',
      getReturnLog: 'getReturnLog',
      setProductToProvider: 'setProductToProviderNew',
      setProductToWarehouse: 'setProductToWarehouseNew',
    },
  },
  sprClass: {
    name: 'SprDirect',
    method: {
      loadProviderList: 'loadProviderList',
      getSprOtherSpr: 'getSprOtherSpr',
      loadCrossSource: 'loadCrossSource',
      loadSourceList: 'loadSourceList',
      setCrossSource: 'setCrossSource',
      deleteCrossSource: 'deleteCrossSource',
      loadDictionaryList: 'loadDictionaryList',
      loadDictionary: 'loadDictionary',
      changeDictionaryAction: 'changeDictionaryAction',
      addNewValueToList: 'addNewValueToList',
    },
  },
  unitClass: {
    name: 'StructureUnit',
    method: {
      loadStructUnitListSmall: 'loadStructUnitListSmall',
    },
  },
  providerClass: {
    name: 'Provider',
    method: {
      loadProviderListInfo: 'loadProviderListInfo',
      loadNewProductInLibrary: 'loadNewProductInLibrary',
      loadNewProductInDate: 'loadNewProductInDate',
      loadProviderInfoAboutEdit: 'loadProviderInfoAboutEdit',
      saveProviderInfoAboutEdit: 'saveProviderInfoAboutEdit',
      deleteProviderAuthInfo: 'deleteAuthInfo',
      addNewAuth: 'addNewAuth',
      deleteProviderManagerInfo: 'deleteProviderManagerInfo',
      addNewManager: 'addNewManager',
      deleteUnitInfo: 'deleteUnitInfo',
      addNewUnitInfo: 'addNewUnitInfo',
      updateUnitInfo: 'updateUnitInfo',
      setDefaultUnitInfo: 'setDefaultUnitInfo',
      loadBrandOnAllPrice: 'loadBrandOnAllPrice',
      loadProviderOnAllPrice: 'loadProviderOnAllPrice',
      loadProductPriceList: 'loadProductPriceList',
      updatePriceProductInfo: 'updatePriceProductInfo',
      deletePriceProduct: 'deletePriceProduct',
      addNewExclude: 'addNewExclude',
      getExcludeList: 'getExcludeList',
      deleteExcludeItem: 'deleteExcludeItem',
      updatePriceOnSite: 'updatePriceOnSite',
    },
  },
  orderManagement: {
    name: 'OrderManagement',
    method: {
      loadSummaryInfo: 'loadSummaryInfo',
      loadOrderList: 'loadOrderList',
      loadTaskList: 'loadTaskList',
      loadPretensionList: 'loadPretensionList',
      loadRequestList: 'loadRequestList',
      loadMoreInfo: 'loadMoreInfo',
    },
  },
  notification: {
    name: 'Notification',
    method: {
      setSubmitNtf: 'setSubmitNtf',
    },
  },
  productLibrary: {
    name: 'ProductLibrary',
    method: {
      loadVideoList: 'loadVideoList',
      addBarCodeProd: 'addBarCodeProd',
      deleteVideo: 'deleteVideo',
      addNewVideo: 'addNewVideo',
    },
  },
  productTree: {
    name: 'ProductTree',
    method: {
      loadAllTreeList: 'loadAllTreeList',
      loadCriByTreeBrand: 'loadCriByTreeBrand',
      offProductTree: 'offProductTree',
      onProductTree: 'onProductTree',
      loadItemInfo: 'loadItemInfo',
      updateTreeItem: 'updateTreeItem',
      loadTreeByBrand: 'loadTreeByBrand',
    },
  },
  dopSaleAnalytics: {
    name: 'DopSaleAnalytics',
    method: {
      loadDataAnalytics: 'loadDataAnalytics',
      loadDataMoreAnalytics: 'loadDataMoreAnalytics',
      loadManagerList: 'loadManagerList',
      loadMoreInfo: 'loadMoreInfo',
    },
  },
  search: {
    name: 'SearchNew',
    method: {
      getHeaderSearch: 'getHeaderSearch',
      getHeaderCrmSearch: 'getHeaderCrmSearch',
      getSearchProductInfo: 'getSearchProductInfo',
      getSearchProductCross: 'getSearchProductCross',
      getSearchProductCrossFilter: 'getSearchProductCrossFilter',
    },
  },
  nomenManager: {
    name: 'NomenManager',
    method: {
      saveNomenEdit: 'saveNomenEdit',
      setReplaceNomen: 'setReplaceNomen',
    },
  },
  fidTools: {
    name: 'FidTools',
    method: {
      loadTreeToFid: 'loadTreeToFid',
    },
  },
};
