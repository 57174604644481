import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SearchService } from '../core/search.service';
import { Observable } from 'rxjs';
import { BrandList, ProductItem, ProductItemCard } from '../core/search';
import { MatDialog } from '@angular/material/dialog';
import { SDialogShowStockInfoComponent } from '../s-dialog-show-stock-info/s-dialog-show-stock-info.component';
import { SDialogShowSettingsInfoComponent } from '../s-dialog-show-settings-info/s-dialog-show-settings-info.component';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  providers: [SearchService],
})
export class GlobalSearchComponent implements OnInit {
  prodList: ProductItemCard[]; // массив товаров
  prodListVar: ProductItemCard[]; // массив товаров
  brandList: [];
  brandF: BrandList[];
  katalNumber: string;
  brand: string;
  productList$: Observable<ProductItem>;
  brandFilter: FormArray; // фильтр по бренду
  oeFilter: number; // фильтр по бренду
  filterForm: FormGroup;
  brandForm: FormControl;
  sortUnit: FormControl = new FormControl();
  prodToDay = 0;
  maxUnit = 0;
  quality = [];
  uniqueSpecInfo = [];

  constructor(
    private route: ActivatedRoute,
    private search: SearchService,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {
    this.brandForm = new FormControl();
    this.brandForm = new FormControl();
    this.brandF = [];

    this.filterForm = this.fb.group({
      brand: this.fb.array([]),
      oe: this.fb.array([1]),
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.katalNumber = params.qk;
      this.brand = params.qb;
      this.loadSearchProduct();
    });
  }

  loadSearchProduct(): void {
    this.productList$ = this.search
      .loadSearchProduct(this.katalNumber, this.brand)
      .pipe(
        tap((val) => {
          this.maxUnit = +val.prod?.saleUnit ?? 0;
          this.prodList = this.prodListVar = val.prodList ?? [];
          this.brandList = this.brandF = val.brandArrList ?? [];
          this.brandFilter = this.filterForm.controls.brand as FormArray;

          this.uniqueSpecInfo = Array.from(
            new Set(
              val.brandArrList
                ?.map((item) => item?.specInfo)
                .filter((specInfo) => specInfo !== '')
            )
          );
          this.setFilterCri(1);
          this.setProductOnStock();
        })
      );
  }

  // показать модальное окно с характеристиками
  showSettings(criList, katalNumber, brand): void {
    this.dialog.open(SDialogShowSettingsInfoComponent, {
      data: {
        criList,
        katalNumber,
        brand,
      },
      maxHeight: '95vh',
    });
  }

  // показать наличие по сладам
  showStock(itemList, katalNumber, brand): void {
    this.dialog.open(SDialogShowStockInfoComponent, {
      data: {
        itemList,
        katalNumber,
        brand,
      },
      maxHeight: '95vh',
    });
  }

  setFilterCri(isChecked: number): void {
    this.oeFilter = isChecked;
    this.filterProd();
  }

  setFilterBrand(brand: any, isChecked: boolean): void {
    this.brandFilter = this.filterForm.controls.brand as FormArray;

    if (isChecked) {
      this.brandFilter.push(new FormControl(String(brand)));
    } else {
      const index = this.brandFilter.controls.findIndex(
        (x) => x.value === brand
      );
      this.brandFilter.removeAt(index);
    }

    if (this.brandFilter.value.length > 0) {
      this.prodListVar = this.prodList.filter((item) => {
        return this.brandFilter.value.includes(item.brand);
      });
    } else {
      this.prodListVar = this.prodList;
    }

    this.filterProd();
  }

  private filterProd(): void {
    const brandList = this.brandFilter ? this.brandFilter.value : [];

    this.prodListVar = [];
    let arr = [];
    if (this.oeFilter === 1) {
      arr = this.prodList.filter((item) => item.setOE === 1);

      if (brandList.length > 0) {
        arr = arr.filter((item) => brandList.includes(item.brand));
        this.prodListVar = arr;
      } else {
        this.prodListVar = arr;
      }
    } else {
      arr = this.prodList.filter((item) => item.setOE === 0);

      if (brandList.length > 0) {
        arr = arr.filter((item) => brandList.includes(item.brand));
        this.prodListVar = arr;
      } else {
        this.prodListVar = arr;
      }
    }
    this.getMaxUnit();
  }

  // Првоерить товары на доступность сегодня
  setProductToDay(): void {
    const arr = [];
    this.prodListVar.forEach((item) => {
      if (
        item.countProviderAll.filter((val) => val.ostatok && +val.ostatok !== 0)
          .length > 0
      ) {
        item.today = true;
      } else {
        item.today = false;
      }
      arr.push(item);
    });
  }

  // Проверить товары на складе
  setProductOnStock(): void {
    const arr = [];
    this.prodToDay = 0;
    this.prodListVar.forEach((item) => {
      if (
        item.countProviderAll.filter((val) => val.stock && +val.stock === 210)
          .length > 0
      ) {
        item.onStock = true;
        this.prodToDay++;
      } else {
        item.onStock = false;
      }
      arr.push(item);
    });
  }

  setBrandFilter(): void {
    this.brandFilter.value.clear();

    this.brandF.forEach((val) => {
      if (this.brandForm.value === val.specInfo) {
        this.setFilterBrand(val.name, true);
      }
    });
  }

  sortBrandList(a, b): any {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }

  getMaxUnit(): void {
    const maxUnit = +this.prodListVar.reduce((max, obj) => {
      return obj?.saleUnit > max ? obj?.saleUnit : max;
    }, this.prodListVar[0]?.saleUnit);

    this.maxUnit = isNaN(this.maxUnit) ? 0 : this.maxUnit;
    if (maxUnit > this.maxUnit) {
      this.maxUnit = maxUnit;
    }
  }

  getUnitChart(saleUnit: number): number {
    return (100 * +saleUnit) / this.maxUnit;
  }

  setQualityBrand(quality, status): void {
    if (status) {
      this.quality.push(quality);
    } else {
      const index = this.quality.indexOf(quality);
      if (index !== -1) {
        // Если число есть в массиве, удаляем его
        this.quality.splice(index, 1);
      }
    }

    const filteredItems: BrandList[] = this.brandList.filter(
      (item: BrandList) => this.quality.includes(item.specInfo)
    );
    this.brandFilter.clear();

    if (filteredItems.length > 0) {
      filteredItems.forEach((item) => {
        this.setFilterBrand(item.name, true);
      });
    } else {
      this.brandList.forEach((item: BrandList) => {
        this.setFilterBrand(item.name, true);
      });
    }
  }

  sortedUnit(): void {
    if (+this.sortUnit.value == 1) {
      this.prodListVar.sort((a, b) => a.saleUnit - b.saleUnit);
    }
    if (+this.sortUnit.value == 2) {
      this.prodListVar.sort((a, b) => b.saleUnit - a.saleUnit);
    }
  }
}
