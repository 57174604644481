import { Component, OnInit } from '@angular/core';
import { filter, startWith, switchMap, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { TableData } from '../../interface/table-data';
import { HeaderService } from '../../core/header.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { HeaderRandomProductComponent } from '../header-random-product/header-random-product.component';
import { SrvService } from 'repositories';

@UntilDestroy()
@Component({
  selector: 'lib-header-task-indicator-list',
  templateUrl: './header-task-indicator-list.component.html',
})
export class HeaderTaskIndicatorListComponent implements OnInit {
  showRandomProdList: number[];
  taskForMeSlide: FormControl = new FormControl(false);
  taskFromMeSlide: FormControl = new FormControl(false);
  requestSlide: FormControl = new FormControl(false);
  randomProd: FormControl = new FormControl(false);

  triggerTFM$ = new Subject();
  triggerTFromMe$ = new Subject();
  triggerPres$ = new Subject();
  triggerRequest$ = new Subject();
  triggerRandomProd$ = new Subject();

  loadTaskForMe$: Observable<TableData> = this.triggerTFM$.pipe(
    tap(() => {
      this.srv.showLoadInfo$.next({
        status: true,
        titleSpinner: 'Ожидайте...',
        subtitle: 'Идет загрузка реестра',
      });
    }),
    startWith({ action: this.taskForMeSlide.value }),
    switchMap((value) =>
      this.hs.loadTaskForMe(value).pipe(
        tap(() => {
          this.srv.showLoadInfo$.next({
            status: false,
          });
        })
      )
    )
  );

  loadTaskFromMe$: Observable<TableData> = this.triggerTFromMe$.pipe(
    tap(() => {
      this.srv.showLoadInfo$.next({
        status: true,
        titleSpinner: 'Ожидайте...',
        subtitle: 'Идет загрузка реестра',
      });
    }),
    startWith({ action: this.taskFromMeSlide.value }),
    switchMap((value) =>
      this.hs.loadTaskFromMe(value).pipe(
        tap(() => {
          this.srv.showLoadInfo$.next({
            status: false,
          });
        })
      )
    )
  );

  loadRequest$: Observable<TableData> = this.triggerRequest$.pipe(
    tap(() => {
      this.srv.showLoadInfo$.next({
        status: true,
        titleSpinner: 'Ожидайте...',
        subtitle: 'Идет загрузка реестра',
      });
    }),
    startWith({ action: this.requestSlide.value }),
    switchMap((value) =>
      this.hs.loadRequest(value).pipe(
        tap(() => {
          this.srv.showLoadInfo$.next({
            status: false,
          });
        })
      )
    )
  );

  loadPretension$: Observable<TableData> = this.triggerPres$.pipe(
    tap(() => {
      this.srv.showLoadInfo$.next({
        status: true,
        titleSpinner: 'Ожидайте...',
        subtitle: 'Идет загрузка реестра',
      });
    }),
    startWith(1),
    switchMap((value) =>
      this.hs.loadPretension(value).pipe(
        tap(() => {
          this.srv.showLoadInfo$.next({
            status: false,
          });
        })
      )
    )
  );
  loadRandomProd$: Observable<TableData> = this.triggerRandomProd$.pipe(
    tap(() => {
      this.srv.showLoadInfo$.next({
        status: true,
        titleSpinner: 'Ожидайте...',
        subtitle: 'Идет загрузка реестра',
      });
    }),
    startWith({ action: this.randomProd.value }),
    switchMap((value) =>
      this.hs.loadRandomProd(value).pipe(
        tap(() => {
          this.srv.showLoadInfo$.next({
            status: false,
          });
        })
      )
    )
  );
  title: any;

  constructor(
    private srv: SrvService,
    private hs: HeaderService,
    private dialog: MatDialog
  ) {
    this.title = 'Заявки на добавление произвольного товара';
    this.srv.userPerm.pipe(filter((val) => val != null)).subscribe((value) => {
      this.showRandomProdList = value.permission.filter((val) => +val === 66);
    });
  }

  ngOnInit(): void {}

  loadTaskForMe(): void {
    this.triggerTFM$.next({ action: this.taskForMeSlide.value });
  }

  setTaskToWork($event: { id: number; category: number }): void {
    if ($event.category !== 2 && $event.category !== 11) {
      this.hs
        .setTaskToWork({ id: $event.id })
        .pipe(untilDestroyed(this))
        .subscribe((val) => {
          if (+val.code === 200) {
            this.srv.successMessage('Задача взятя в работу');
            this.loadTaskForMe();
            this.loadTaskFromMe();
          } else {
            this.srv.errorMessage('Ошибка обратитесь к администратору');
          }
        });
    }
  }

  loadTaskFromMe(): void {
    this.triggerTFromMe$.next({ action: this.taskFromMeSlide.value });
  }

  setToArchive($event: { id: number }): void {
    this.hs
      .setTaskToArchive({ id: $event.id })
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        if (+val.code === 200) {
          this.srv.successMessage('Задача перемещена в архив');
          this.loadTaskForMe();
          this.loadTaskFromMe();
        } else {
          this.srv.errorMessage('Ошибка обратитесь к администратору');
        }
      });
  }

  loadRequestSlide(): void {
    this.triggerRequest$.next({ action: this.requestSlide.value });
  }

  loadTaskRandomInfo(): void {
    this.triggerRandomProd$.next({ action: this.randomProd.value });
  }

  showRandomCard($event: any): void {
    const dialog = this.dialog.open(HeaderRandomProductComponent, {
      data: {
        item: $event,
      },
      maxHeight: '94vh',
      minWidth: '40vw',
    });

    dialog
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadTaskRandomInfo();
      });
  }
}
