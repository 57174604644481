import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../../../core/services/api.service';
import { RequestBody } from '../../../../core/interfaces/interfaces';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-dialog-more-call-order',
  templateUrl: './dialog-more-call-order.component.html',
  styleUrls: ['./dialog-more-call-order.component.scss'],
})
export class DialogMoreCallOrderComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  cols = [];
  rows = [];
  footer = {
    class: '',
    colspan: 0,
    zakaz: 0,
    createDate: '',
    sumOrder: 0,
    manager: '',
  };
  loading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private api: ApiService,
    private srv: SrvService
  ) {}

  ngOnInit(): void {
    this.onLoadMoreInfo();
  }

  onLoadMoreInfo(): void {
    this.loading = true;
    const dialogInfo = this.dialogData;

    const row = dialogInfo.row;
    const field = dialogInfo.field;
    const startDate = this.srv.convertDate(dialogInfo.startDate);
    const endDate = this.srv.convertDate(dialogInfo.endDate);

    const q: RequestBody = {
      modelName: 'AnalyticsCount',
      calledMethod: 'getMoreCallOrderInfo',
      methodProperties: {
        type: 1,
        startDate,
        endDate,
        field,
        row,
      },
    };

    this.subscription = this.api.post_jwt(q).subscribe((spr) => {
      this.loading = false;

      this.cols = spr.headerList;
      this.rows = spr.orderList;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
