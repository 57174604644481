<div class='container-fluid'>
  <div class='row my-2'>
    <div class='col'>
      <h1 class='h1'>
        {{ "SEARCH_GLOBAL.TITLE" | translate }}
      </h1>
    </div>
  </div>

  <div class='row' *ngIf='productList$ | async as productList; else loading'>
    <div class='col-12 col-md-3'>
      <mat-card>
        <button
          mat-button
          class='btn btn-primary btn-block mb-2'
          (click)='setProductOnStock()'
        >
          Товары на складе
          <span *ngIf='prodToDay' class='text-warning' style='font-size: 22px'
          ><b>{{ prodToDay }} шт</b></span
          >
        </button>

        <button
          mat-button
          class='btn btn-success btn-block mb-2'
          (click)='setProductToDay()'
        >
          {{ "SEARCH_GLOBAL.PRODUCT_TO_DAY" | translate }}
        </button>
        <br />
        <br />
        <mat-card-title>
          {{ "SEARCH_GLOBAL.SET_OE" | translate }}
        </mat-card-title>

        <mat-card-content>
          <mat-radio-group (change)='setFilterCri($event.value)'>
            <mat-radio-button [value]='1' checked>
              Проверенные
            </mat-radio-button>
            <br />

            <mat-radio-button [value]='2'> Не проверенные</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>

        <br />
        <!--
        <mat-card-title> Тип бренда </mat-card-title>

        <mat-card-content>
          <mat-radio-group
            [formControl]="brandForm"
            (change)="setBrandFilter()"
          >
            <mat-radio-button
              style="display: block"
              *ngFor="let item of productList.specBrandInfo"
              [value]="item"
            >
              {{ item.slice(0, 20) }}
            </mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
-->

        <mat-card-title>
          По уровню качества
        </mat-card-title>
        <mat-card-content>
          <mat-checkbox *ngIf='uniqueSpecInfo.includes(1)' color='primary' #checkQuality1
                        (change)='setQualityBrand(1,checkQuality1.checked)'>Средний
          </mat-checkbox>
          <br>
          <mat-checkbox *ngIf='uniqueSpecInfo.includes(3)' color='primary' #checkQuality3
                        (change)='setQualityBrand(3,checkQuality3.checked)'>Выше
            среднего
          </mat-checkbox>
          <br>
          <mat-checkbox *ngIf='uniqueSpecInfo.includes(4)' color='primary' #checkQuality4
                        (change)='setQualityBrand(4,checkQuality4.checked)'>Премиум
          </mat-checkbox>
          <br>
          <mat-checkbox *ngIf='uniqueSpecInfo.includes(5)' color='primary' #checkQuality5
                        (change)='setQualityBrand(5,checkQuality5.checked)'>Высокий
          </mat-checkbox>

        </mat-card-content>


        <mat-card-title>
          {{ "SEARCH.BRAND" | translate }}
        </mat-card-title>
        <mat-card-content>
          <div *ngFor='let item of productList.brandArrList'>
            <mat-checkbox
              color='primary'
              [checked]='brandF.includes(item.name)'
              (change)='setFilterBrand(item.name, $event.checked)'
            >
              <span [ngSwitch]='item.specInfo'>
                <mat-icon
                  *ngSwitchCase='1'
                  matTooltip='Средний'
                  matTooltipClass='tooltip'
                >menu_open</mat-icon
                >
                <mat-icon
                  *ngSwitchCase='2'
                  matTooltip='Оригинал'
                  matTooltipClass='tooltip'
                >auto_fix_high</mat-icon
                >
                <mat-icon
                  *ngSwitchCase='3'
                  matTooltip='Выше среднего'
                  matTooltipClass='tooltip'
                >keyboard_capslock</mat-icon
                >
                <mat-icon
                  *ngSwitchCase='4'
                  matTooltip='Премиум'
                  matTooltipClass='tooltip'
                >emoji_events</mat-icon
                >
                <mat-icon
                  *ngSwitchCase='5'
                  matTooltip='Высокий'
                  matTooltipClass='tooltip'
                >h_mobiledata</mat-icon
                >
                <span
                  *ngSwitchDefault
                  style='display: inline-block; width: 24px'
                ></span>
              </span>
              {{ item.name }} {{ "SEARCH.BRAND_COST" | translate }}
              {{ item.cost }} грн
            </mat-checkbox>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class='col-12 col-md-9'>

      <div class='h2'>
        {{ "SEARCH_GLOBAL.TITLE_2" | translate }} {{ katalNumber }} {{ brand }} <br>
      </div>
      <div *ngIf="productList.prod?.treeProd">Товарная группа - <b>{{productList.prod?.treeProd}}</b></div>
      <div *ngIf="productList.prod?.hubSale">Детали на которые обратить внимание при замене -
        <span *ngFor="let item of productList.prod?.hubSale"><b>{{item.data}}</b>, </span></div>

      <div
        class='row'
        *ngIf='
          productList.prod && productList.prod.typeProd != 2;
          else showFirstProd
        '
        [ngClass]='{
            rowBackgroundDanger: +productList.prod.codeProvider == 210
          }'
      >
        <div class='col'>
          <div
            class='row align-items-center py-2 hover'
            style='border-bottom: 1px solid #000'
          >
            <div class='col-2 text-center'>
              <img
                alt=''
                [src]='productList.prod.photo'
                style='max-width: 100%; max-height: 80px'
              /><br />
              <div
                class='btn btn-warning'
                *ngIf='+productList.prod.codeProvider == 210'
              >
                Товар со склада
              </div>
              <div *ngIf='productList.prod.gift' >
                <button mat-icon-button class='btn btn-danger' matTooltipClass='tooltip' [matTooltip]='productList.prod.brandGift'><mat-icon>card_giftcard</mat-icon></button>
              </div>
              <div class='box-position' [ngSwitch]='productList.prod.infoLabel'>
                <div
                  *ngSwitchCase='1'
                  class='saleRate px-3 py-2'
                  [matTooltip]="'SEARCH_GLOBAL.SALE_TIME' | translate"
                  matTooltipClass='tooltip'
                >
                  {{ "SEARCH_GLOBAL.SALE" | translate }}
                </div>
                <div *ngSwitchCase='2' class='countSale px-3 py-2'>
                  {{ "SEARCH_GLOBAL.TOP" | translate }}
                </div>
                <div *ngSwitchCase='3' class='selfRec px-3 py-2'>
                  {{ "SEARCH_GLOBAL.REC" | translate }}
                </div>
              </div>
            </div>
            <div class='col-10'>
              <div class='row'>
                <div class='col-4'>
                  <div class='row'>
                    <div class='col'>
                      <a
                        [href]="
                          '/crm/product/' +
                          productList.prod.katalNumber +
                          '_' +
                          productList.prod.brand +
                          '?oe=' +
                          this.katalNumber
                        "
                        target='_blank'
                      >{{ productList.prod.nomen }}</a
                      >
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col'>
                      <small
                      ><b
                      >{{ productList.prod.katalNumber }}
                        {{ productList.prod.brand }}</b
                      >
                        ({{ productList.prod.country }})</small
                      >
                      <div *ngFor='let val of productList.prod.arrayCri'>
                        <small *ngIf="val.name == 'Место установки'"
                        >{{ val.name }} - {{ val.value }}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col' [ngClass]="{
                    'chart-danger':getUnitChart(productList.prod.saleUnit)<25,
                    'chart-warning':getUnitChart(productList.prod.saleUnit)>=25 && getUnitChart(productList.prod.saleUnit)<50,
                    'chart-primary':getUnitChart(productList.prod.saleUnit)>=50 && getUnitChart(productList.prod.saleUnit)<75,
                    'chart-success':getUnitChart(productList.prod.saleUnit)>75
                    }">
                      <mat-progress-bar
                        [color]="'primary'"
                        [mode]="'determinate'"
                        [value]='getUnitChart(productList.prod.saleUnit)'>
                      </mat-progress-bar>
                      {{ productList.prod.saleUnit }} юнитов.
                    </div>
                  </div>
                </div>
                <div class='col-3 px-1'>
                  <app-provider-count-table
                    [providerInfo]='productList.prod.countProvider'
                  ></app-provider-count-table>
                </div>
                <div class='col-2 px-1 text-center'>
                  <div
                    class='my-1'
                    [ngSwitch]='+productList.prod.saleCost.type'
                  >
                    <div *ngSwitchCase='1'>
                      <div class='d-block align-items-center'>
                        <span class='h4 pr-2'
                        ><b>{{ "SEARCH_GLOBAL.COST" | translate }}:</b></span
                        >
                        <span
                          style='position: relative'
                          class='text-center text-dark'
                        >
                          <b>{{ productList.prod.saleCost.oldCost }}</b> грн.
                          <div
                            style='
                              border-bottom: 1px solid #eb5757;
                              position: absolute;
                              width: 100%;
                              top: 0.6rem;
                              left: 0;
                              transform: rotate(13deg);
                            '
                          ></div>
                        </span>
                        <div class='text-center'>
                          <span class='h2 text-danger'
                          ><b>{{
                            productList.prod.saleCost.newCost
                            }}</b></span
                          >
                          &nbsp;<span class='text-danger'>грн.</span>
                        </div>
                      </div>
                    </div>
                    <div *ngSwitchCase='2'>
                      <div class='d-block align-items-center'>
                        <span class='h4 pr-2'
                        ><b>{{ "SEARCH_GLOBAL.COST" | translate }}:</b></span
                        >
                        <span
                          style='position: relative'
                          class='text-center text-dark'
                        >
                          <b>{{ productList.prod.saleCost.oldCost }}</b> грн.
                          <div
                            style='
                              border-bottom: 1px solid #eb5757;
                              position: absolute;
                              width: 100%;
                              top: 0.6rem;
                              left: 0;
                              transform: rotate(13deg);
                            '
                          ></div>
                        </span>
                        <div class='text-center'>
                          <span class='h2 text-danger'
                          ><b>{{
                            productList.prod.saleCost.newCost
                            }}</b></span
                          >
                          &nbsp;<span class='text-danger'>грн.</span>
                        </div>
                      </div>
                    </div>
                    <div *ngSwitchDefault>
                      <button
                        [matMenuTriggerFor]='menu'
                        mat-button
                        class='btn btn-outline-primary'
                      >
                        <b
                        >{{ "SEARCH_GLOBAL.COST" | translate }}
                          {{ productList.prod.saleCost.oldCost }} грн.</b
                        >
                      </button>
                      <mat-menu #menu='matMenu'>
                        <button mat-menu-item>
                          {{ "SEARCH_GLOBAL.COST_SALE" | translate }}
                          <b>{{ productList.prod.saleCost.newCost }} грн.</b>
                        </button>
                      </mat-menu>
                    </div>
                  </div>

                </div>
                <div class='col-1 px-1 text-center'>
                  <button mat-button class='btn btn-primary' [matMenuTriggerFor]='menu'>
                    Информация о товаре
                  </button>
                  <mat-menu #menu='matMenu'>
                    <div class='my-1'>
                      <button
                        mat-button
                        class='btn btn-small btn-gray'
                        [matTooltip]="'SEARCH_GLOBAL.SEND' | translate"
                        matTooltipClass='tooltip'
                        matTooltipPosition='before'
                      >
                        <mat-icon> local_shipping</mat-icon>
                        <span
                          style='width: 12px; display: inline-block'
                          [matBadge]='productList.prod.countSaleProd'
                          matBadgePosition='after'
                        ></span>
                      </button>
                    </div>
                    <div class='my-1'>
                      <button
                        mat-button
                        class='btn btn-small btn-gray'
                        [matTooltip]="'SEARCH_GLOBAL.RETURN' | translate"
                        matTooltipClass='tooltip'
                        matTooltipPosition='before'
                      >
                        <mat-icon> keyboard_return</mat-icon>
                        <span
                          style='width: 12px; display: inline-block'
                          [matBadge]='productList.prod.countReturn'
                          matBadgePosition='after'
                        ></span>
                      </button>
                    </div>
                    <div
                      class='my-1'
                      [innerHTML]='productList.prod.infoManger'
                    ></div>

                    <div class='my-1' *ngIf='productList.prod.specInfo'>
                      <button
                        mat-button
                        class='btn btn-small btn-warning'
                        [matTooltip]='productList.prod.specInfo'
                        matTooltipClass='tooltip'
                        matTooltipPosition='after'
                      >
                        <mat-icon> info</mat-icon>
                      </button>
                    </div>
                  </mat-menu>


                </div>

              </div>
              <app-form-add-to-order
                [orderList]='productList.orderList'
                [setOe]='productList.prod.setOE'
                [oeNum]='katalNumber'
                [katalNumber]='productList.prod.katalNumber'
                [brand]='productList.prod.brand'
                [id]='productList.prod.id'
              ></app-form-add-to-order>
            </div>
          </div>
        </div>
      </div>
      <ng-template #showFirstProd>
        Искомого товара {{ katalNumber }} {{ brand }} нет в наличии
      </ng-template>

      <mat-divider></mat-divider>

      <div class='h2 my-3'>Товары аналоги</div>

      <mat-divider></mat-divider>
      <div *ngIf='prodListVar.length > 0; else showInfo'>
        <mat-form-field>
          <mat-label>Соритировка по юнитам</mat-label>
          <mat-select [formControl]='sortUnit' (selectionChange)='sortedUnit()'>
            <mat-option [value]='1'>По возрастанию</mat-option>
            <mat-option [value]='2'>По убыванию</mat-option>
          </mat-select>
        </mat-form-field>

        <div
          class='row align-items-center py-2 hover'
          style='border-bottom: 1px solid #000'
          *ngFor='let item of prodListVar'
          [ngClass]='{
            rowBackground: item.today,
            rowBackgroundDanger: item.onStock
          }'
        >
          <div class='col-2 text-center'>
            <img
              alt=''
              [src]='item.photo'
              style='max-width: 100%; max-height: 80px'
            /><br />
            <div class='btn btn-warning' *ngIf='+item.codeProvider == 210'>
              Товар со склада
            </div>

            <div *ngIf='item.gift' >
              <button mat-icon-button class='btn btn-danger' matTooltipClass='tooltip' [matTooltip]='item.brandGift'><mat-icon>card_giftcard</mat-icon></button>
            </div>
          </div>
          <div class='col-10'>
            <div class='row'>
              <div class='col-4'>
                <div class='row'>
                  <div class='col'>
                    <a
                      [href]="
                        '/crm/product/' +
                        item.katalNumber +
                        '_' +
                        item.brand +
                        '?oe=' +
                        this.katalNumber
                      "
                      target='_blank'
                    >{{ item.nomen }}</a
                    >
                  </div>
                </div>
                <div class='row'>
                  <div class='col'>
                    <div>
                      <small
                      ><b>{{ item.katalNumber }} {{ item.brand }} </b>({{
                        item.country
                        }}
                        - {{ item.brandInfo }})</small
                      >
                    </div>
                    <div *ngFor='let val of item.arrayCri'>
                      <small *ngIf="val.name == 'Место установки'"
                      >{{ val.name }} - {{ val.value }}
                      </small>
                    </div>
                  </div>
                </div>
                <div class='row'
                     [ngClass]="{
                    'chart-danger':getUnitChart(item.saleUnit)<25,
                    'chart-warning':getUnitChart(item.saleUnit)>=25 && getUnitChart(item.saleUnit)<50,
                    'chart-primary':getUnitChart(item.saleUnit)>=50 && getUnitChart(item.saleUnit)<75,
                    'chart-success':getUnitChart(item.saleUnit)>75
                    }"
                >
                  <mat-progress-bar
                    [color]="'primary'"
                    [mode]="'determinate'"
                    [value]='getUnitChart(item.saleUnit)'>
                  </mat-progress-bar>
                  <div class='col'>{{ item.saleUnit }} юнитов.</div>
                </div>
              </div>
              <div class='col-3 px-1'>
                <app-provider-count-table
                  [providerInfo]='item.countProvider'
                ></app-provider-count-table>
              </div>
              <div class='col-2 px-1 text-center'>
                <div class='my-1' [ngSwitch]='+item.saleCost.type'>
                  <div *ngSwitchCase='1'>
                    <div class='d-block align-items-center'>
                      <span class='h4 pr-2'
                      ><b>{{ "SEARCH_GLOBAL.COST" | translate }}:</b></span
                      >
                      <span
                        style='position: relative'
                        class='text-center text-dark'
                      >
                        <b>{{ item.saleCost.oldCost }}</b> грн.
                        <div
                          style='
                            border-bottom: 1px solid #eb5757;
                            position: absolute;
                            width: 100%;
                            top: 0.6rem;
                            left: 0;
                            transform: rotate(13deg);
                          '
                        ></div>
                      </span>
                      <div class='text-center'>
                        <span class='h2 text-danger'
                        ><b>{{ item.saleCost.newCost }}</b></span
                        >
                        &nbsp;<span class='text-danger'>грн.</span>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase='2'>
                    <div class='d-block align-items-center'>
                      <span class='h4 pr-2'
                      ><b>{{ "SEARCH_GLOBAL.COST" | translate }}:</b></span
                      >
                      <span
                        style='position: relative'
                        class='text-center text-dark'
                      >
                        <b>{{ item.saleCost.oldCost }}</b> грн.
                        <div
                          style='
                            border-bottom: 1px solid #eb5757;
                            position: absolute;
                            width: 100%;
                            top: 0.6rem;
                            left: 0;
                            transform: rotate(13deg);
                          '
                        ></div>
                      </span>
                      <div class='text-center'>
                        <span class='h2 text-danger'
                        ><b>{{ item.saleCost.newCost }}</b></span
                        >
                        &nbsp;<span class='text-danger'>грн.</span>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchDefault>
                    <button
                      [matMenuTriggerFor]='menu'
                      mat-button
                      class='btn btn-outline-primary'
                    >
                      <b
                      >{{ "SEARCH_GLOBAL.COST" | translate }}
                        {{ item.saleCost.oldCost }} грн.</b
                      >
                    </button>
                    <mat-menu #menu='matMenu'>
                      <button mat-menu-item>
                        {{ "SEARCH_GLOBAL.COST_SALE" | translate }}
                        <b>{{ item.saleCost.newCost }} грн.</b>
                      </button>
                    </mat-menu>
                  </div>
                </div>
                <div class='my-1' *ngIf='productList.prod.specInfo'>
                  <button
                    mat-button
                    class='btn btn-small btn-warning'
                    [matTooltip]='productList.prod.specInfo'
                    matTooltipClass='tooltip'
                    matTooltipPosition='after'
                  >
                    <mat-icon> info</mat-icon>
                  </button>
                </div>
              </div>

              <div class='col-1 px-1 text-center'>

                <button mat-button class='btn btn-primary' [matMenuTriggerFor]='menu'>
                  Информация о товаре
                </button>
                <mat-menu #menu='matMenu'>
                  <div class='my-1'>
                    <button
                      mat-button
                      class='btn btn-small btn-gray'
                      [matTooltip]="'SEARCH_GLOBAL.SEND' | translate"
                      matTooltipClass='tooltip'
                      matTooltipPosition='before'
                    >
                      <mat-icon> local_shipping</mat-icon>
                      <span
                        style='width: 12px; display: inline-block'
                        [matBadge]='item.countSaleProd'
                        matBadgePosition='after'
                      ></span>
                    </button>
                  </div>
                  <div class='my-1'>
                    <button
                      mat-button
                      class='btn btn-small btn-gray'
                      [matTooltip]="'SEARCH_GLOBAL.RETURN' | translate"
                      matTooltipClass='tooltip'
                      matTooltipPosition='before'
                    >
                      <mat-icon> keyboard_return</mat-icon>
                      <span
                        style='width: 12px; display: inline-block'
                        [matBadge]='item.countReturn'
                        matBadgePosition='after'
                      ></span>
                    </button>
                  </div>
                  <div class='my-1' [innerHTML]='item.infoManger'></div>
                </mat-menu>

              </div>
            </div>

            <app-form-add-to-order
              [orderList]='productList.orderList'
              [setOe]='item.setOE'
              [katalNumber]='item.katalNumber'
              [brand]='item.brand'
              [oeNum]='katalNumber'
              [id]='item.id'
            ></app-form-add-to-order>
          </div>
        </div>
      </div>
      <ng-template #showInfo>
        <div class='h2'>{{ "SEARCH_GLOBAL.NOT_FOUND" | translate }}</div>
      </ng-template>
    </div>
  </div>
  <ng-template #loading>
    <div
      class='d-flex justify-content-center my-5'
      style='height: 600px; padding-top: 200px'
    >
      <mat-spinner diameter='50'></mat-spinner>
    </div>
  </ng-template>
</div>
<app-scroll-top></app-scroll-top>
