import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { SrvService } from 'repositories';

@Injectable({ providedIn: 'root' })
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private srv: SrvService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig = {};
    const userToken = localStorage.getItem('auth-token');
    // Токен идентификация пользователя
    if (userToken) {
      headersConfig['auth-token'] = userToken;
    }
    // Токен для доступа к БД
    headersConfig['token'] = environment.token;
    const request = req.clone({ setHeaders: headersConfig });
    // return next.handle(request);

    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            // Если токен не валидный - выбрасывать пользователя для авторизации
            if (event.body.code == 401) {
              this.auth.logout();
            }
          }
        },
        (error) => {
          switch (error.status) {
            case 500:
              this.srv.errorMessage(
                'Ошибка работы сервера, Обратитесь к администратору'
              );
              break;
          }
        }
      )
    );
  }
}
