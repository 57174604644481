import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RsService } from './core/rs.service';
import { Subject, Subscription } from 'rxjs';
import { MatOption } from '@angular/material/core';
import { OtherSpr, RequestBody } from '../../../core/interfaces/interfaces';
import { ApiService } from '../../../core/services/api.service';
import { RsList } from './core/rs';
import { MatDialog } from '@angular/material/dialog';
import { DialogMarkComponent } from './dialog-mark/dialog-mark.component';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-romi',
  templateUrl: './romi.component.html',
  providers: [RsService],
})
export class RomiComponent implements OnInit, OnDestroy {
  form: FormGroup;
  subscription: Subscription;
  sourceList: OtherSpr[];
  carList: OtherSpr[];
  prodGroupList: OtherSpr[];
  loading: boolean;
  cols: any[];
  orderList: RsList[] = [];
  footerInfo;
  getCMS;
  pieData;
  getRemarket;
  lineDataArr;
  @ViewChild('allSelectedSource') private allSelectedSource: MatOption;
  @ViewChild('allSelectedCar') private allSelectedCar: MatOption;
  @ViewChild('allSelectedGroup') private allSelectedGroup: MatOption;

  constructor(
    private rs: RsService,
    public srv: SrvService,
    private api: ApiService,
    private dialog: MatDialog
  ) {
    this.footerInfo = {
      class: '',
      colspan: '',
      grossSale: '',
      investments: '',
      romi: '',
    };

    this.form = new FormGroup({
      start: new FormControl(new Date(), [Validators.required]),
      end: new FormControl(new Date(), [Validators.required]),
      source: new FormControl(['2', '6', '8', '9', '12', '11']),
      campany: new FormControl(''),
      typeFilter: new FormControl('3'),
      prodGroup: new FormControl(''),
      dataType: new FormControl('2'),
      car: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.getDynamicSpr();
  }

  // загрузить занные по показателю
  getRomiInfo(): boolean {
    if (this.form.invalid) {
      return false;
    }
    this.lineDataArr = [];

    this.loading = true;
    const formVal = this.form.value;
    const q: RequestBody = {
      modelName: 'AnalyticsRomi',
      calledMethod: 'getRomiAnalytics',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
        typeFilter: formVal.typeFilter,
        dataType: formVal.dataType,
        source: this.deleteAllMark(formVal.source),
        car: this.deleteAllMark(formVal.car),
        prodGroup: this.deleteAllMark(formVal.prodGroup),
      },
    };

    this.subscription = this.api.post_jwt(q).subscribe((spr) => {
      this.cols = spr.headerList;
      this.orderList = spr.orderList;
      this.footerInfo = spr.footer;
      this.getCMS = spr.getCMS;
      this.getRemarket = spr.getRemarket;
      this.loading = false;

      const labels = [];
      const gross = [];
      const invest = [];
      const arrMarkInfo = [];

      const labelList = [];
      const dataList = [];
      const backgroundColorList = [];
      this.orderList.forEach((item) => {
        labelList.push(item.filter);
        dataList.push(
          this.srv.roundNumber(
            (item.grossSale * 100) / this.footerInfo.grossSale
          )
        );
        backgroundColorList.push(this.dynamicColors());
      });

      this.pieData = {
        labels: labelList,
        datasets: [
          {
            data: dataList,
            backgroundColor: backgroundColorList,
          },
        ],
      };

      this.orderList.forEach((val) => {
        labels.push(val.filter);
        gross.push(val.grossSale);
        invest.push(val.investments);
      });

      this.lineDataArr.push({
        labelChart: 'ROMI',
        labels,
        datasets: [
          {
            label: 'Валовая прибыль',
            data: gross,
            fill: false,
            borderColor: '#4bc0c0',
          },
          {
            label: 'Инвестиции',
            data: invest,
            fill: false,
            borderColor: '#565656',
          },
        ],
      });

      const arrOrderDate2 = [];
      const arrGrossList = [];
      const arrContext = [];

      const arrDateRange = this.srv.getDatesRange(
        this.srv.convertDate(this.srv.convertDate(formVal.start)),
        this.srv.convertDate(this.srv.convertDate(formVal.end)),
        +formVal.dataType
      );

      const grossList = new Map();
      spr.arrGross.forEach((val1) => {
        grossList.set(val1.dateCreate, +val1.cost);
      });

      const contextList = new Map();
      spr.arrContext.forEach((val1) => {
        contextList.set(val1.dateCreate, +val1.cost);
      });

      arrDateRange.forEach((itemDate) => {
        if (grossList.get(itemDate)) {
          arrGrossList.push(grossList.get(itemDate));
        } else {
          arrGrossList.push(0);
        }

        if (contextList.get(itemDate)) {
          arrContext.push(contextList.get(itemDate));
        } else {
          arrContext.push(0);
        }

        arrOrderDate2.push(itemDate);
      });
      this.lineDataArr.push({
        labelChart: 'ROMI - за дату',
        labels: arrOrderDate2,
        datasets: [
          {
            label: 'Валовая прибыль',
            data: arrGrossList,
            fill: false,
            borderColor: '#4bc0c0',
          },
          {
            label: 'Инвестиции',
            data: arrContext,
            fill: false,
            borderColor: '#565656',
          },
        ],
      });

      spr.arrMarkInfo.forEach((val) => {
        const arrOrderDate3 = [];

        const arrGrossListMark = [];
        const arrContextMark = [];

        const grossListMark = new Map();
        val.dataSale.forEach((val1) => {
          grossListMark.set(val1.dateCreate, +val1.sale);
        });

        const contextListMark = new Map();
        val.dataCost.forEach((val1) => {
          contextListMark.set(val1.dateCreate, +val1.cost);
        });

        arrDateRange.forEach((itemDate) => {
          if (grossListMark.get(itemDate)) {
            arrGrossListMark.push(grossListMark.get(itemDate));
          } else {
            arrGrossListMark.push(0);
          }

          if (contextListMark.get(itemDate)) {
            arrContextMark.push(contextListMark.get(itemDate));
          } else {
            arrContextMark.push(0);
          }

          arrOrderDate3.push(itemDate);
        });
        this.lineDataArr.push({
          labelChart:
            val.mark +
            ' | ROMI: ' +
            val.romi +
            '% | Доход:' +
            val.gross +
            ' грн.',
          labels: arrOrderDate3,
          datasets: [
            {
              label: 'Доход',
              data: arrGrossListMark,
              fill: false,
              borderColor: '#4bc0c0',
            },
            {
              label: 'Инвестиции',
              data: arrContextMark,
              fill: false,
              borderColor: '#565656',
            },
          ],
        });
      });
    });
  }

  // удалить признак отметки всех
  deleteAllMark(arr): any {
    const index = arr.indexOf('all');
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  // загрузить динамические справочники
  getDynamicSpr(): void {
    const formVal = this.form.value;

    const getSourceReason: RequestBody = {
      modelName: 'AnalyticsRomi',
      calledMethod: 'getSprSourceRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getSourceReason).subscribe((spr) => {
      this.sourceList = spr;
    });

    const getCar: RequestBody = {
      modelName: 'AnalyticsRomi',
      calledMethod: 'getSprCarRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getCar).subscribe((spr) => {
      this.carList = spr;
    });

    const getProdGroup: RequestBody = {
      modelName: 'AnalyticsRomi',
      calledMethod: 'getSprProdGroupRange',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };
    this.subscription = this.api.post_jwt(getProdGroup).subscribe((spr) => {
      this.prodGroupList = spr;
    });
  }

  toggleAllSource(): void {
    if (this.allSelectedSource.selected) {
      this.form.controls.source.patchValue([
        ...this.sourceList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.source.patchValue([]);
    }
  }

  toggleAllCar(): void {
    if (this.allSelectedCar.selected) {
      this.form.controls.car.patchValue([
        ...this.carList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.car.patchValue([]);
    }
  }

  // функции выбрать все
  toggleAllGroup(): void {
    if (this.allSelectedGroup.selected) {
      this.form.controls.prodGroup.patchValue([
        ...this.prodGroupList.map((item) => item.value),
        'all',
      ]);
    } else {
      this.form.controls.prodGroup.patchValue([]);
    }
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  showMarkInfo(filterId: any): void {
    const formVal = this.form.value;

    this.dialog.open(DialogMarkComponent, {
      data: {
        mark: filterId,
        dateStart: this.srv.convertDate(formVal.start),
        dateEnd: this.srv.convertDate(formVal.end),
      },
      minWidth: '80vw',
      maxHeight: '90vh',
    });
  }

  dynamicColors(): string {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }
}
