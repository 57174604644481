import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../../core/services/api.service';
import { RequestBody } from '../../../../core/interfaces/interfaces';

@Component({
  selector: 'app-dialog-info',
  templateUrl: './dialog-info.component.html',
  styleUrls: ['./dialog-info.component.scss'],
})
export class DialogInfoComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  cols = [];
  rows = [];
  loading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private api: ApiService
  ) {
  }

  ngOnInit(): void {
    this.onLoadMoreInfo();
  }

  onLoadMoreInfo(): void {
    this.loading = true;

    const dialogInfo = this.dialogData;

    const managerId = dialogInfo.manager;
    const info = dialogInfo.col;
    const startDate = dialogInfo.startDate;
    const endDate = dialogInfo.endDate;

    const q: RequestBody = {
      modelName: 'Analytics',
      calledMethod: 'getSalaryMoreInfo',
      methodProperties: {
        type: 1,
        startDate,
        endDate,
        manager: managerId,
        info,
      },
    };

    this.subscription = this.api.post_jwt(q).subscribe((spr) => {
      this.loading = false;

      this.cols = spr.headerList;
      this.rows = spr.orderList;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
