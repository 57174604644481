import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HeaderService } from '../../core/header.service';
import { Observable, Subject } from 'rxjs';
import { SprList } from '../../interface';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { SrvService } from 'repositories';

@UntilDestroy()
@Component({
  selector: 'lib-header-search-user',
  templateUrl: './header-search-user.component.html',
})
export class HeaderSearchUserComponent implements OnInit {
  title: string;
  form = new FormGroup({
    phone: new FormControl('', [Validators.required]),
    name: new FormControl(''),
    nameRecipient: new FormControl(''),
    orderNumber: new FormControl(''),
    orderTtn: new FormControl(''),
  });
  trigger$ = new Subject();
  getSearchInfo$: Observable<SprList[]> = this.trigger$.pipe(
    debounceTime(500),
    tap(() => {
      this.srv.showLoadInfo$.next({
        status: true,
        titleSpinner: 'Ожидайте...',
        subtitle: 'Идет загрузка реестра',
      });
    }),
    switchMap((value) =>
      this.hs.searchClientInfo(value).pipe(
        tap(() => {
          this.srv.showLoadInfo$.next({
            status: false,
          });
        })
      )
    )
  );

  constructor(
    private hs: HeaderService,
    private srv: SrvService,
    private dialogRef: MatDialogRef<HeaderSearchUserComponent>,
    private router: Router
  ) {
    this.title = 'Создать клиента';
  }

  ngOnInit(): void {}

  searchInfo(): void {
    let typeSearch: number;

    if (this.form.value.phone.length > 3) typeSearch = 1;
    if (this.form.value.name.length > 3) typeSearch = 2;
    if (this.form.value.nameRecipient.length > 3) typeSearch = 3;
    if (this.form.value.orderNumber.length > 3) typeSearch = 4;
    if (this.form.value.orderTtn.length > 3) typeSearch = 5;

    this.trigger$.next({ ...this.form.value, type: typeSearch });
  }

  clearData(): void {
    this.form.controls.phone.setValue('');
    this.form.controls.name.setValue('');
    this.form.controls.nameRecipient.setValue('');
    this.form.controls.orderNumber.setValue('');
    this.form.controls.orderTtn.setValue('');
  }

  createNewClient(): boolean {
    if (this.form.invalid) {
      this.srv.successMessage('Нет телефона или Имени клиента');

      return false;
    }

    this.srv.showLoadInfo$.next({
      status: true,
      titleSpinner: 'Ожидайте...',
      subtitle: 'Идет создание клиента',
    });

    this.hs
      .createNewClient(this.form.value)
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.srv.showLoadInfo$.next({
          status: false,
        });
        this.dialogRef.close();
        this.router
          .navigate([`/crm/user-card-phone/${val.hash.toString()}/null`])
          .then();
      });
  }
}
